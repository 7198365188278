.advertisement-video-modal {
  & > .modal-ent__header {
    padding: 0px 20px 20px 20px;
    a {
      color: $white;
      text-decoration: underline;
    }
  }
  & > .modal-ent__content {
    height: 720px;
    img {
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    iframe {
      margin: auto;
    }
  }
}
