.multiselect-custom {
  &_wrapper {
    margin-right: 30px;
    &.__tiny {
      .btn-custom_main.btn-transparent.__tiny {
        height: 26px;
      }
      .dropdown-content {
        margin-top: 0 !important;
        font-size: 12px !important;
      }
      .fucking-first-element {
        ul li:first-child {
          font-size: 14px;
        }
      }
    }
  }

  &_inner-wrapper {
    display: flex;
  }

  &_btn {
    &.__wrapper {
      position: relative;
      margin-right: 15px;
      .btn-custom_main {
        white-space: nowrap;
        padding-left: 5px;
        &.bg-yellow {
          background-color: $light-yellow !important;
        }
      }
      &.__button-select {
        height: 32px;
      }
    }
  }

  &_title {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: $discrete-text;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    &.__black {
      color: $black;
    }
    &.__small {
      font-size: 12px;
      margin-bottom: 10px;
    }
    &.__tiny {
      font-size: 10px;
      margin-bottom: 10px;
    }
    & > p {
      margin-bottom: 0px;
    }
  }

  &_select.rmsc,
  &_select {
    position: absolute;
    top: 35px;
    z-index: 99;
    &.__button-select {
      visibility: hidden;
      height: 0px;
      width: 0px;
      top: -30px;
    }
    .dropdown {
      &-container {
        border: 0;
        box-shadow: none;
      }
      &-heading {
        width: auto;
        display: none;
        .clear-selected-button {
          display: none;
        }
      }
      &-content {
        margin-top: 5px;
        padding-top: 0;
        width: auto;
        max-width: 300px;
        min-width: 260px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        border: 0;
        z-index: 20;
        animation: fadeIn ease 0.2s;
        .panel-content {
          box-shadow: none;
          background-color: $white;
        }
        .select-item {
          padding: 5px 12px;
          &.selected {
            background-color: #e6f7fe !important;
            font-weight: 600;
          }
          .item-renderer {
            position: relative;
            padding-right: 20px;
            &.disabled {
              cursor: default;
            }
            &:before {
              position: absolute;
              content: "";
              top: 50%;
              right: 5px;
            }
            span {
              padding-left: 0;
            }
            input[type="checkbox"] {
              display: none;
            }
          }
        }
      }
    }
    &.fucking-first-element {
      ul li:first-child {
        font-size: 15px;
      }
    }
    &.with-search-row {
      .dropdown-content > .panel-content > .select-panel > div:first-child {
        padding: 10px;
        border: none;
        & > input {
          height: 34px;
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          padding: 0px 30px 0px 10px;
        }
        & > button {
          height: 20px;
          margin: 16px 18px 0px 3px;
          padding: 2px;
        }
      }
    }
  }

  &_list {
    &.__wrapper {
      width: 100%;
      &.__non-stretch {
        width: unset;
      }
    }
    &.__inner-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }
  }

  &_item {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: $link;
    border-radius: 50px;
    display: flex;
    align-items: center;

    &.__red {
      background-color: $red;
      &.__transparent {
        color: $red;
        background-color: $white;
      }
    }
    &.__purple {
      background-color: $purple;
      &.__transparent {
        color: $purple;
        background-color: $white;
      }
    }
    &.__black {
      background-color: $black;
      &.__transparent {
        color: $black;
        background-color: $white;
      }
    }
    &.__green {
      background-color: $green;
      border: 1px solid $green;
      &.__transparent {
        background-color: $white;
        & > span {
          color: $green;
        }
      }
    }

    span {
      font-size: 16px;
      line-height: 1;
      color: #ffffff;
    }
    .btn-custom__delete {
      height: 20px;
      padding: 0;
      svg {
        max-height: 100%;
        max-width: 100%;
      }
    }

    &.__small {
      padding: 5px 5px 5px 10px;
      span {
        font-size: 12px;
      }
    }
    &.__tiny {
      padding: 5px 5px 5px 10px;
      span {
        font-size: 12px;
      }
      .btn-custom__delete {
        margin: -1px 0px 1px 0px;
        height: 16px;
        width: 16px;
      }
    }
    &.__bold > span {
      font-weight: 700;
    }
    &.__no-remove-button.__small {
      padding: 9px 5px 9px 10px;
    }
  }
}
