.modal-identify {
  &_wrapper {
    min-height: 50vh;
    padding: 80px;
  }
  &_image {
    margin-bottom: 30px;
    svg,
    img {
      height: 100%;
      max-height: 200px;
    }
  }
  &_link {
    display: block;
    margin: 0 auto 30px;
    font-size: 16px;
    font-weight: 700;
  }
  &_text {
    margin: 0 auto 10px;
    font-size: 16px;
    max-width: 400px;
  }
  &_spin {
    .preloader500 {
      height: auto;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .modal-identify {
    &_text {
      margin: 0 auto 30px;
    }
    &_link {
      display: none;
    }
  }
}
