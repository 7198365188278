.content-tag {
  &_title {
    font-size: 12px;
    font-weight: 700;
  }
  &_tag {
    border-radius: 3px !important;
    height: 22px !important;
    font-size: 10px !important;
    line-height: 20px !important;
    font-weight: 700;
  }
  &_tag.ant-tag-checkable {
    &-checked {
      background-color: $green !important;
      color: $white;
      &:focus,
      &:active,
      &::selection {
        border-color: $green !important;
        background-color: $green !important;
        outline-color: $green !important;
      }
    }
    border-color: $green;
    color: $green;
    user-select: none;
    &:focus,
    &:active,
    &::selection {
      border-color: $green !important;
      background-color: $green !important;
      outline-color: $green !important;
      color: $white !important;
    }
  }
}

.closable-tags {
  &_wrapper {
    display: flex;
    flex-flow: row wrap;
  }
  &_tag.ant-tag {
    border-radius: 20px;
    border: none;
    background-color: $link;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    color: $white;
    padding: 0px 5px 0px 15px;
    svg {
      height: 40px;
      margin: -1px 0px -13px 10px;
      cursor: pointer;
    }
    .anticon-close {
      line-height: 20px;
      height: 20px;
      width: 20px;
      vertical-align: -5px;
      margin: 0px 5px 0px -20px;
      border-radius: 10px;
      svg {
        display: none;
      }
    }
    &.__small {
      height: 30px;
      border-radius: 15px;
      font-size: 12px;
      line-height: 30px;
      padding: 0px 3px 0px 15px;
      svg {
        height: 30px;
        margin: -1px 0px -9px 10px;
      }
      .anticon-close {
        line-height: 15px;
        height: 15px;
        width: 15px;
        vertical-align: -3px;
        margin: 0px 3px 0px -15px;
        border-radius: 7px;
      }
    }
    &.blue,
    &.link {
      background-color: $link;
    }
    &.green {
      background-color: $green;
    }
    &.red {
      background-color: $red;
    }
    &.purple {
      background-color: $purple;
    }
    &.orange {
      background-color: $orange;
    }
  }
}

.custom-tag {
  border-radius: 3px !important;
  &.__large {
    font-size: 16px;
    padding: 5px 10px;
    line-height: 27px;
    font-weight: 700;
  }
  &.__medium {
    font-size: 14px;
    padding: 4px 5px;
    line-height: 18px;
    font-weight: 700;
  }
  &.__small {
    font-size: 10px;
    padding: 0px 4px;
    line-height: 16px;
    font-weight: 700;
  }
  &.__tiny {
    font-size: 8px;
    padding: 0px 2px;
    line-height: 14px;
    font-weight: 700;
  }
  &.__uppercase {
    text-transform: uppercase !important;
  }
  &.__bold {
    font-weight: 700;
  }
  &.__no-background {
    background: none;
  }
  &.__mini-select-view,
  &.__medium-select-view {
    background: none;
    border-color: $black;
    height: 23px !important;
    padding: 0px 10px;
    font-size: 10px;
    border-radius: 12px !important;
    margin: 0px;
  }
  &.__mini-select-view {
    height: 23px !important;
    font-size: 10px;
  }
  &.__medium-select-view {
    height: 26px !important;
    line-height: 24px;
    font-size: 12px;
  }
  & > svg {
    margin: 0px -5px -2px 10px;
    height: 12px;
  }
}
