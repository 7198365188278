.table {
  &-title-results {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title {
      font-size: 24px;
      font-weight: 800;
      margin-right: 30px;
      margin-bottom: 0;
    }
    .total-items {
      font-size: 24px;
      font-weight: 800;
      color: $mid-gray;
    }
  }
}

.table-head {
  &_wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px;
  }
  &_column {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    svg {
      margin-right: 10px;
    }
    &.__uppercase {
      text-transform: uppercase;
    }
    &.__bold {
      font-weight: 700;
    }
    &.__12px_font {
      font-size: 12px;
    }
  }
}

.table-row {
  &_wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px;
    &.__top-border {
      border-top: 1px dashed $light-gray;
    }
    &.__bottom-border {
      border-bottom: 1px dashed $light-gray;
    }
  }
  &_cell {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    svg {
      margin-right: 10px;
    }
    &.__bold {
      font-weight: 700;
    }
    &.__12px_font {
      font-size: 12px;
    }
    &_label.__is-cell-link {
      color: $link;
      text-decoration: underline;
    }
    &_label.__is-column-link {
      color: $link;
      text-decoration: underline;
    }
  }
}
