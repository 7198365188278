.invite-suppliers {
  &_wrapper {
    position: relative;
    .custom-title {
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .btn-custom_wrap {
      display: flex;
      justify-content: space-between;
    }
  }
  &_table-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 40px;
    @include for-size(sm) {
      margin-top: -40px;
      margin-bottom: 20px;
      align-items: center;
      h6 {
        margin-bottom: 0 !important;
      }
    }
  }
  &_form {
    margin-bottom: 80px;
    max-width: 540px;
    &__item {
      display: flex;
      position: relative;
      margin-bottom: 20px;
      .form {
        width: 100%;
        margin-right: 20px;
        .input {
          width: 100%;
          padding: 20px;
        }
      }
    }
  }
  &__btn-send {
    margin-bottom: 15px;
    margin-right: 20px;
  }
  &_table-head {
    &_wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0px;
    }
    &_column {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 12px;
      text-transform: uppercase;
      svg {
        margin-right: 10px;
      }
      &_label {
        white-space: nowrap;
      }
      &:nth-child(1),
      &:nth-child(2) {
        @include for-size(md) {
          width: 35% !important;
        }
      }
      &:nth-child(3) {
        @include for-size(md) {
          width: 30% !important;
        }
      }
      &:nth-child(4),
      &:nth-child(5) {
        @include for-size(md) {
          display: none;
        }
      }
    }
  }
  &_table-row {
    &_wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0px;
      border-top: 1px dashed $light-gray;
    }
    &_email,
    &_company,
    &_sent,
    &_signup {
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }
    &_email,
    &_company {
      word-break: break-all;
      @include for-size(md) {
        width: 35% !important;
      }
    }
    &_email {
      width: 25%;
    }
    &_company {
      width: 20%;
    }
    &_sent,
    &_signup {
      width: 11%;
      @include for-size(md) {
        width: 30%;
      }
    }
    &_empty {
      width: 33%;
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      svg {
        margin: 0px 5px;
      }
    }
    &_signup,
    &_empty {
      @include for-size(md) {
        display: none;
      }
    }
  }
}
