.modal-report {
  &_wrapper {
    .ant-modal {
      &-content {
        padding-bottom: 15px;
        padding-right: 15px;
      }
      &-header {
        border-bottom: 0;
      }
      &-footer {
        display: flex;
        justify-content: flex-end;
        border: 0;
        padding-top: 0px;
      }
    }
  }
  &_btn {
    &__close {
      margin-right: 20px;
    }
  }
}
