.presentation-card {
  margin-bottom: 60px;
  padding-right: 40px;

  h4 {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 2px;
  }

  &.__contact-person {
    margin-bottom: 30px;
    padding-right: 0;
  }

  &.id06-section {
    & > div.presentation-card__content {
      height: 25px;
    }
    .id06-section__label {
      font-size: 12px !important;
      color: $discrete-text;
    }
    &__value {
      font-size: 14px;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    h4 {
      margin-bottom: 0;
      font-weight: 700;

      span {
        margin-left: 5px;
      }
    }

    img {
      margin-right: 10px;
      max-width: 21px;
    }

    svg {
      margin-right: 10px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__select.ant-select {
    margin-left: 15px;
    .ant-select-selection-item {
      padding-right: 30px !important;
    }
  }
}

.insurance-card {
  font-size: 12px;
  align-items: center;
  min-height: 37px;
  display: flex;

  &__first-parameter {
    flex-basis: 20%;
    font-weight: 600;
  }

  &__second-parameter {
    flex-basis: 15%;
  }

  &__table-column {
    width: 25%;
    margin: 0 20px 0 0;
    &.__title,
    &.__data {
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      text-overflow: ellipsis;
      &.__wide-column {
        display: flex;
        width: 25%;
      }
    }
    &.__title {
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 700;
      margin-bottom: 5px;
    }
    &.__unnamed-cell {
      color: $mid-gray;
      font-style: italic;
    }
    &.__data {
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__download {
    display: flex;
    margin: 0;
    padding: 5px 0px 5px 5px;
    width: calc(100% - 25px);
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    background: transparent;
    border-radius: 3px;
    border-color: rgba(27, 162, 204, 0.1) !important;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &:hover {
      border-color: rgba(27, 162, 204, 0.3) !important;
    }

    border: none;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    color: $link;
    cursor: pointer;

    svg {
      margin-right: 5px;
      min-width: 20px;
    }
  }

  &__first-parameter.__manual-data,
  &__second-parameter.__manual-data,
  &__data.__manual-data,
  &__meets.__manual-data {
    margin: 0;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include for-size(xl) {
      margin-right: 20px;
    }

    span {
      color: $mid-gray;
      margin-right: 8px;
    }
  }
}

.edit-our-services {
  &_wrapper {
    display: flex;
    flex-wrap: wrap;

    &._main-filters {
      .edit-our-services_item {
        width: auto;
      }

      .blob-title {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .blob-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;

        .edit-our-services_item.__min {
          margin-right: 0px;

          .edit-our-services_selected__item {
            margin-right: 0px;
          }
        }
      }
    }
  }

  &_item {
    margin-right: 30px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    p {
      width: 100%;
    }

    &__inner-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }

  &_title {
    margin-bottom: 20px;
    font-size: 14px;
    color: $black;
    font-weight: 700;
    text-transform: uppercase;
  }

  &_sub-title {
    margin-bottom: 10px;
    font-size: 12px;
    color: $black;
    font-weight: 700;
    text-transform: uppercase;
  }

  &_selected {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 10px 15px;
      color: $discrete-text;
      font-size: 16px;
      line-height: 1;
      border: 1px solid $discrete-text;
      border-radius: 50px;
    }
  }
}

.edit-insurance_wrapper {
  .editBlock_item::before {
    width: 90%;
  }

  .edit-insurance_field__input {
    flex-grow: 2;
  }

  .editBlock {
    &_field__item,
    &_select__item {
      margin-right: 15px;
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 2;
      .form {
        display: flex;
      }
    }
  }

  .editBlock_flex__wrap {
    justify-content: space-between;
    flex-grow: 2;

    .editBlock_date-picker__item {
      width: 130px;

      .editBlock_date-picker__field.ant-picker {
        min-width: 130px !important;
        width: 130px;
      }
    }

    .editBlock_radio {
      &__title {
        padding-left: 0px;
        margin-right: 10px;
      }

      &__wrapper {
        padding-top: 20px;
        flex-flow: row nowrap;
      }

      &__switch {
        display: flex;
        flex-flow: row wrap;
      }

      &__item {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 10px;

        label {
          font-size: 12px;
        }
      }
    }

    .editBlock_upload__item {
      flex-flow: row nowrap;
      padding-top: 25px;
      margin-right: 0px;
    }
    &.without-labels {
      .editBlock_upload__item {
        padding-top: 0px;
        div.ant-upload {
          margin-top: 0;
        }
      }
    }
  }
}
