@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .presentation-card {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    &__title {
      flex-flow: row wrap;
      & > .presentation-card__select {
        margin: 10px 0px 0px 0px;
        width: 100%;
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .contact-person {
        &_wrapper {
          justify-content: space-between;
        }
        &_item {
          width: 100%;
          min-width: auto;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          padding: 15px;
          border: 1px dotted $light-gray;
          border-radius: 3px;
        }
      }

      .insurance-card {
        width: 100%;
        min-width: auto;
        flex-wrap: wrap;
        justify-content: inherit;
        margin-bottom: 20px;
        padding: 10px;
        border: 1px dotted $light-gray;
        border-radius: 3px;
        font-size: 10px;
        p {
          width: 25%;
          min-width: 25%;
          margin-right: 0;
          max-width: 100%;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 5px;
        }
        &__download {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    &.__insurance-area {
      .insurance-card {
        flex-flow: column nowrap;
        justify-content: flex-start;
        & > p {
          width: 100%;
          min-width: 100%;
          margin-bottom: 5px;
        }
      }
    }
    &.__member-of-area,
    &.__collective-union-agreements-area {
      .insurance-card {
        border: 0;
        padding: 0px 10px 0px 10px;
        margin: 0;
        .insurance-card__table-column {
          margin-bottom: 5px;
        }
      }
    }
  }

  .edit-insurance_wrapper {
    margin-bottom: 40px;
    .editBlock_head {
      margin-bottom: 10px;
      &__actions > button {
        width: 50%;
        justify-content: center;
        margin: 0px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .editBlock_body {
      .services-offered_item__inner {
        align-items: center;
      }
      .editBlock_select__item,
      .editBlock_field__item {
        margin-right: 0px;
      }
      .editBlock_date-picker__item {
        width: 100%;
        .editBlock_date-picker__field {
          width: 100% !important;
        }
      }
    }
    .btn-custom_wrap {
      margin-top: 20px;
      & > button {
        width: 100%;
        margin: 0px;
      }
    }
  }

  .edit-our-services {
    &_item {
      margin-right: 0;
    }
    &_wrapper .blob-item {
      align-items: flex-start !important;
      & > div:nth-child(2) {
        padding-top: 8px;
      }
    }
  }

  .custom-table {
    &_head {
      display: none;
    }
    &_body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &__item {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: auto;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        padding: 15px;
        border: 1px dotted $light-gray;
        border-radius: 3px;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      &__value {
        width: 100%;
        margin-right: 0;
        display: flex;
        justify-content: space-between;
        width: 100% !important;
        &:before {
          position: relative;
          content: attr(data-title);
          display: inline-block;
          margin-right: 10px;
        }
        &.__big {
          width: 100%;
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .rating-observer {
    &_head {
      flex-wrap: wrap;
      margin-bottom: 30px;
      &__collapse {
        display: block;
        width: 100%;
        .rating-observer_item__wrapper {
          margin-bottom: 20px;
        }
      }
      &__btn {
        width: 100%;
        max-width: 260px;
        border: 1px solid #6b6b6b;
        border-radius: 3px;
        margin: 0 auto;
        &:before {
          right: 12px;
        }
      }
    }
  }
}
