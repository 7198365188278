.modal-exists {
  min-height: 500px;
  @include for-size(md) {
    height: auto;
  }
  button {
    align-items: center;
    padding: 20px 0;
    @include for-size(md) {
      font-size: 14px;
    }
  }
  &__btns {
    display: block;
    margin-top: 60px;
    button {
      display: block;
      margin: 0 auto 20px auto;
    }
  }
  .btn {
    width: 471px;
    display: block;
    @include for-size(md) {
      margin-top: 30px;
      padding: 0 10px;
    }
  }
  .btn-second {
    height: 61px;
    width: 542px;
    display: inline-block;
    margin-bottom: 50px;
    @include for-size(md) {
      padding: 0 10px;
    }
  }
  &__text {
    margin-bottom: 20px;
  }
}

.modal-request {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  @include for-size(md) {
    height: auto;
  }
  &__text {
    margin-bottom: 40px;
    font-size: 16px;
    width: 60%;
  }
  &__textarea {
    width: 400px;
    text-align: left;
    font-size: 16px;
    .textarea {
      width: 100%;
      resize: none;
      font-size: 14px;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      padding: 20px;
      outline: none;
      border: none;
      border-radius: 3px;
      margin: 20px 0px 40px 0px;
    }
  }

  &__button {
    margin: auto;
    width: 300px;
    justify-content: center;
  }
}

.text-success {
  color: $green;
  margin-top: 64px;
  @include for-size(md) {
    font-size: 11px;
    margin-bottom: 40px;
  }
}
