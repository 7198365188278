.services-section {
  &_wrapper {
    padding-left: 10px;
    .editBlock {
      &_body {
        .first-setup_selection {
          &__item-wrap {
            &.__with-dash {
              .blob-title {
                font-size: 14px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
