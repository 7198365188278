.organizations {
  padding: 20px;
  border-radius: 3px;
  position: relative;
  &.__results {
    background-color: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  }
  &__suppliers {
    position: relative;
    margin-bottom: 40px;
    .subscription-upgrade-modal {
      &__wrapper {
        top: 40px;
        right: 10px;
        @include for-size(sm) {
          top: unset;
          bottom: 40px;
          position: fixed !important;
          width: 100% !important;
          height: 128px;
          right: 10%;
        }
      }
    }
    .subscription-upgrade-modal-local__wrapper {
      top: 106px;
      right: 44px;
    }
  }
  &__results {
    margin-bottom: 65px;
    display: flex;
    align-items: center;
    .custom-title {
      margin-right: 30px;
      margin-bottom: 0;
    }
    span {
      font-weight: bold;
      font-size: 24px;
      color: $mid-gray;
    }
  }
}
