.our-contracts {
  background: $white;
  padding: 20px 10px;
  border-radius: 3px;
  &-table-title {
    margin-top: 50px;
  }
  .empty-data {
    text-align: center;
    font-weight: 700;
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      padding: 40px 45px;
      svg {
        margin: 2px 20px 0px 0px;
        width: 40px;
        height: 36px;
      }
    }
    &_wrapper {
      min-height: 50vh;
      .not-bold h3 {
        font-weight: 400;
      }
    }
    &_title {
      line-height: 31px;
      font-weight: 800;
    }
  }
  .contracts-project-label {
    padding-top: 14px;
  }
  .requests-card {
    &_head {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      &__column .contracts-project-label .ant-select-selector {
        padding-left: 0px;
      }
    }

    &_head,
    &_body {
      width: 100%;
      margin-left: 0;

      &:hover {
        .icon-col {
          svg > path {
            fill: $blue;
          }
        }
      }

      &.not-signed {
        opacity: 0.4;
        &:hover {
          opacity: 1;
        }
      }

      .icon-col {
        width: 30px;
      }
      .number-col {
        &.__wrapper {
          width: 110px;
        }
      }
      .name-col {
        &.__wrapper {
          width: auto;
          margin-right: 0;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 10px;
        }
      }
      .company-col {
        &.__wrapper {
          width: auto;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 10px;
        }
      }
      .date-col {
        &.__wrapper {
          width: 160px;
        }
      }
      .project-col {
        &.__wrapper {
          width: 200px;
          padding-right: 0;
        }
        .project-label_wrapper {
          max-width: 100%;
        }
      }
      .truncate {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_head {
      .name-col,
      .date-col {
        padding-top: 1px;
      }
    }
  }
}
