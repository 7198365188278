@import "current-messages";

.requests-to-us {
  background: $white;

  @media (min-width: 320px) and (max-width: 991.98px) {
    max-width: 100%;
  }

  margin-bottom: 40px;
  border-radius: 3px;
  .request-to-us_header__wrapper {
    .project-etc {
      @include for-size(sm) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
        margin-bottom: 20px !important;
      }
    }
    .title-date {
      @include for-size(sm) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
      }
    }
    .view-header__above-title {
      color: $mid-gray;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .buyer-org {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
    .contact-person {
      display: flex;
      align-items: center;
      margin-top: 14px;
    }
    .project-and-location {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
    }
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      padding: 40px 45px;
      svg {
        margin: 4px 20px 0px 0px;
      }
    }
    &_wrapper {
      min-height: 50vh;
      max-width: unset;

      .request-to-us__tabs {
        display: flex;
        @include for-size(sm) {
          .tab {
            font-size: 12px;
          }
        }
        .create-offer-button {
          margin: -3px 10px 0px 0px;
          padding-left: 5px;

          span {
            font-size: 14px;
          }
        }
        button.tab > a {
          display: flex;
          flex-flow: row nowrap;
          span {
            height: 16px;
          }
        }
        &__tab-line {
          font-size: 25px;
          font-weight: 300;
          margin: -9px 30px 0px 0px;
          @include for-size(sm) {
            display: none;
          }
        }
      }
    }
    &_title {
      line-height: 31px;
    }
  }
  &_head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    &__icon {
      /* hiding for now til we have auto-selection */
      visibility: hidden;
      display: none;

      width: 50px;
      svg {
        margin-top: 3px;
      }
      &.__select {
        .ant-select {
          min-width: 55px;
          .ant-select-selector {
            background-color: transparent;
            border: 0;
            font-weight: 700;
            padding-left: 2px;
            &:after {
              display: none;
            }
            &:hover {
              background-color: #efefef;
            }
            .ant-select-selection-item {
              position: relative;
              padding-right: 30px;
              &:after {
                position: absolute;
                content: "";
                display: block;
                top: calc(50% + 2px);
                right: 3px;
                width: 10px;
                height: 10px;
                transform: translateY(-50%);
                border: 6px solid transparent;
                border-top: 6px solid $link;
                transition: 0.2s;
                visibility: inherit;
              }
            }
          }
          &.ant-select-open,
          &.ant-select-focused {
            .ant-select-selector {
              box-shadow: none !important;
            }
          }
          .ant-select-arrow {
            display: none;
          }
        }
      }
    }
    &__project {
      width: calc(25% - 20px);
      margin-right: 20px;
    }
    &__name {
      width: calc(25% - 20px);
      margin-right: 20px;
    }
    &__column {
      width: 15%;
      &.__buyer {
        width: calc(20% - 20px);
        margin-right: 20px;
      }
      &.__date {
        width: calc(15% - 20px);
        margin-right: 20px;
      }
    }

    &__response {
      width: calc(17% - 20px);
      margin-left: 0;
    }
  }
  &_body {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    border-radius: 3px;
    transition: 0.3s;
    color: $black;
    cursor: pointer;

    &.unread {
      font-weight: 700;
    }
    &:hover {
      border-color: rgba($link, 0.3);
      color: $link;
      .icon-table {
        fill: $link;
      }
      .project-name {
        border: none;
        background: $purple;
        color: white;
      }
      .badge sup {
        background-color: $link;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px dotted $light-gray;
    }
    &__icon {
      width: 50px;

      /* hiding for now until we have auto-selection */
      visibility: hidden;
      display: none;
    }

    &__project {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 10px;
      width: calc(25% - 20px);
      margin-right: 20px;

      span {
        display: flex;
        width: 100%;
      }
      svg {
        flex: none;
        align-self: center;
        margin-right: 5px;
      }
      .project-name {
        display: block;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid $purple;
        color: $purple;
        padding: 0px 5px;
        border-radius: 3px;
      }
    }
    &__name {
      width: calc(25% - 20px);
      margin-right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .notifier-dot {
        display: inline-flex;
        margin: 0 2px -6px 0;
      }
    }
    &__column {
      width: 15%;
      &.__date {
        width: calc(15% - 20px);
        margin-right: 20px;
        line-height: 25px;
      }
      &.__buyer {
        width: calc(20% - 20px);
        margin-right: 20px;
      }
    }
    &__response {
      width: calc(17% - 20px);
      margin-left: 0;
      font-size: 12px;

      &.green {
        color: $green;
      }
      &.red {
        color: $red;
      }
    }
  }
  .contract-tab {
    &__title {
      font-size: 16px;
      font-weight: 700;
    }
    &_documents {
      &_wrapper {
        display: flex;
        border-bottom: 1px dotted $light-gray;
      }
      &_buyer {
        width: 65%;
      }
      &_seller {
        width: 35%;
      }
      &_title {
        font-size: 12px;
        font-weight: 700;
        padding-bottom: 20px;
        border-bottom: 1px dotted $light-gray;
      }
      &_columns {
        display: flex;
        &.__links .load-document-column__body {
          min-height: auto;
        }
      }
      .load-document-column__title {
        min-height: 32px;
        margin-bottom: 15px;
      }
    }
    &_signatures {
      &__title {
        font-size: 16px;
        font-weight: 700;
      }
      &__side {
        width: 50%;
        &.__min {
          width: 30%;
        }
      }
      &__input {
        width: 65%;
      }
      &__waiting-text {
        font-size: 12px;
        color: $orange;
        font-weight: 700;
        display: flex;
        line-height: 20px;
        svg {
          margin-right: 10px;
        }
      }
      &__contact {
        display: flex;
        flex-flow: column nowrap;
        font-size: 14px;
        span {
          font-weight: 700;
        }
        .__date {
          color: $mid-gray;
          font-size: 12px;
        }
      }
    }
    &_done-signatures {
      border-top: 1px dotted $light-gray;
      display: flex;
      flex-flow: column nowrap;
      font-size: 12px;
      &__title {
        color: $mid-gray;
      }
      &__value {
        font-weight: 500;
      }
    }
  }

  .message-card__messages-wrapper,
  .message-card__messages-list {
    .message-panel {
      &_initials.initials-badge,
      &_other-initials {
        height: 40px;
        width: 42px;
        font-size: 16px;
        line-height: 36px;
        border: 2px solid #fff;
        margin-left: 3px;
      }
      &_initials {
        background-color: $green;
        color: $white;
      }
    }
  }

  .message-card__messages-list {
    padding-top: 3px;
    max-height: 470px;
    overflow: scroll;
  }
}

.current-request {
  &_text {
    &.__wrapper {
      margin-bottom: 40px;
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &_contacts {
    &.__wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &.__title {
      text-transform: uppercase;
      margin-right: 40px;
      margin-bottom: 0;
    }
    &.__initials {
      display: inline-block;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      font-size: 8px;
      line-height: 20px;
      font-weight: 700;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
      user-select: none;
      text-transform: uppercase;
    }
    &.__name {
      font-size: 14px;
      text-transform: capitalize;
      display: flex;
      margin-right: 20px;
      line-height: 1;
      align-items: center;
    }
    &.__phone {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 15px;
      }
    }
  }
  &_docs {
    &.__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      @include for-size(sm) {
        flex-direction: column;
      }
    }
  }
}

.current-our-offer {
  &_info {
    &.__wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }
    &.__item {
      margin-right: 80px;
      align-self: flex-start;
      h6 {
        margin-bottom: 10px;
        color: $mid-gray;
        font-size: 10px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
      }
      & > div {
        display: flex;
        align-items: baseline;
        & > span {
          font-weight: 800;
          font-size: 24px;
          text-transform: uppercase;
          color: $green;
        }
        & > span.currency {
          font-size: 16px;
          margin: 0px 10px 0px 5px;
          color: $mid-gray;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  &_sub-info {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px dotted #a9a9a9;
    &.__wrapper {
      display: flex;
      flex-wrap: nowrap;
      @include for-size(l) {
        flex-wrap: wrap;
      }
    }
  }
  &_message-text {
    &.__wrapper {
      width: 60%;
      min-width: 25%;
      flex-shrink: 5;
      padding-right: 40px;
      margin-right: 40px;
      border-right: 1px dotted #a9a9a9;
    }
    &.__title {
      margin-bottom: 20px;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
      svg,
      img {
        flex: 0 0 auto;
        width: 20px;
        margin-right: 10px;
      }
    }
  }
  &_documents {
    flex-grow: 2;
    &.load-docs_wrapper {
      margin-top: 0px;
      min-width: 0;
    }
    .load-docs_column-load {
      width: 100%;
    }
  }
  &__documents {
    margin-top: 0;
  }
  &_edit {
    &.__wrapper {
      .current-our-offer_edit > label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        padding-left: 20px;
        min-width: 180px;
      }
    }
    &.__field {
      width: 50%;
      padding: 0 15px 0 0;
      input {
        width: 100%;
        height: 60px;
        background: $white;
        border: none;
        font-size: 14px;
        line-height: 17px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        outline: none;
        padding-left: 20px;
      }
    }
    &.__message {
      width: 100%;
      textarea {
        display: block;
        margin-bottom: 15px;
        padding: 10px 20px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 120px;
        max-height: 200px;
        background: #ffffff;
        border: none;
        font-size: 14px;
        color: #626262;
        line-height: 17px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        outline: none;
      }
    }
    &.__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: -70px;
      margin-bottom: 20px;
      position: relative;
      &__status-label {
        padding: 0px 20px 0px 5px;
        font-size: 12px;
      }
      button {
        margin-right: 10px;
      }
      &__status-draft {
        position: absolute;
        top: -40px;
        right: 10px;
        color: $discrete-text;
      }
    }
    .load-docs_column-load {
      width: 100%;
    }
  }
}
