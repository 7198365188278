.account_member {
  &__title {
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
  }
  &__item {
    width: 70%;
    min-width: 600px;
    &:not(:last-of-type) {
      border-bottom: 1px dotted $light-gray;
    }
    padding: 10px 0;
    position: relative;

    &-head {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    &__name,
    &-head__name {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 64%;
      &__full {
        font-size: 16px;
        text-transform: capitalize;
        margin: 0;
        line-height: 1.3em;
      }
      &__email {
        font-size: 12px;
        @include for-size(sm) {
          font-size: 10px;
        }

        color: $discrete-text;
        margin-bottom: 0px;
      }
    }
    &__regions,
    &-head__regions {
      width: 36%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      @include for-size(sm) {
        display: none;
      }
      .btn-custom_main.__tiny {
        width: 16px;
        height: 16px;
        padding: 0;
        > svg {
          margin-right: 0;
        }
      }
      &-tooltip {
        min-width: 480px;
        .ant-tooltip-content {
          width: 480px;
        }
      }
    }

    &__settings,
    &-head__settings {
      width: 3%;

      @include for-size(sm) {
        display: none;
      }
      svg {
        margin-right: 0px;
      }
    }
  }
}

.members {
  padding: 0 40px 40px 40px;
  @include for-size(sm) {
    padding: 0 20px 40px 20px;
  }
  &__title {
    display: flex;
    align-items: center;
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      margin: 0 40px 0 0;
    }
    span {
      color: $mid-gray;
      font-size: 14px;
    }
  }

  .header-wrapper {
    width: 70%;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    .video-instruction-link {
      margin-left: 40px;
      font-size: 12px;
    }
    @include for-size(md) {
      align-items: flex-start;
      flex-direction: column;
      .video-instruction-link {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
  &__subscription-text {
    font-size: 14px;
    margin-bottom: 80px;
    @include for-size(md) {
      margin-bottom: 40px;
      width: 90%;
    }
  }
  &__manager {
    margin-bottom: 80px;
  }

  &__summary {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    color: $discrete-text;
    text-transform: uppercase;
    margin-top: 30px;
  }
}

.member-settings-modal {
  &__rfp-switch {
    padding: 20px 0px;
    border-top: 1px dashed $light-gray;
    border-bottom: 1px dashed $light-gray;
  }
  &__roles-select {
    margin-bottom: 20px;
    border-bottom: 1px dashed $light-gray;
  }
  &__regions-select {
    padding: 20px 0px 10px 0px;
  }
}

.member {
  border-bottom: 1px dotted $light-gray;
  max-width: 390px;
  @include for-size(md) {
    width: 100%;
  }
  &__btns {
    display: flex;
    margin-bottom: 16px;
    @include for-size(xs) {
      flex-direction: column;
    }
  }
  &__dropdowns {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    width: 100%;
    padding: 20px 0;
    @include for-size(md) {
      align-items: flex-start;
      justify-content: space-between;
    }
    p {
      margin-left: 10px;
      margin-bottom: 0;
      @include for-size(md) {
        margin: 0;
      }
    }
    &__wrapper {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
    &__name {
      display: flex;
      align-items: center;
      width: 60%;
      @include for-size(md) {
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__owner {
      width: 35%;
    }
    &__arrow {
      cursor: pointer;
    }
  }
  &_notification__wrapper {
    margin-bottom: 60px;
    max-width: 1000px;
    &.__box {
      border-radius: 3px;
      padding: 20px;
      border: 1px solid $light-gray;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
      max-height: 438px;
      overflow-y: auto;
    }
  }

  &_notification {
    &__item {
      padding: 20px;
      background: $orange;
      color: $white;
      width: 100%;
      border-radius: 3px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &__desc {
      display: flex;
      align-items: center;
      width: 70%;
      p {
        margin-bottom: 0;
      }
      svg {
        margin-right: 27px;
      }
    }
    &__message {
      font-weight: 700;
      // font-size: 14px !important;
      &__wrapper {
        // color: $green;
        border-radius: 3px;
        border: 1px solid $white;
        padding: 10px;
        // margin-bottom: 40px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
    }
    &__btn {
      cursor: pointer;
      border-radius: 3px;
      padding: 10px;
      border: 1px solid $white;
      color: #fff;
      height: auto;
      background-color: transparent;
      margin: 0 15px;
      &:hover {
        background-color: rgba(#fff, 0.1);
      }
      &__filled {
        cursor: pointer;
        border-radius: 3px;
        padding: 10px;
        border: 1px solid $white;
        height: auto;
        color: $orange;
        background: $white;
        margin-left: 20px;
        white-space: nowrap;

        &:hover {
          background-color: rgba(#fff, 0.95);
        }
      }
    }
  }
}

.member_actions {
  &__dropdown {
    display: block;
    padding-left: 5px;
  }
}

.members_choice-recipients-modal {
  .ant-modal-body {
    .ant-modal-confirm-title {
      font-weight: 700;
    }
  }
  &__description {
    font-size: 16px;
  }
}
