.custom-tooltip {
  & > .ant-tooltip-content > .ant-tooltip-inner {
    white-space: break-spaces;
    border-radius: 3px;
  }
  &.center-text {
    .ant-tooltip-content > .ant-tooltip-inner {
      text-align: center;
    }
  }
  &.no-wrap-text {
    max-width: unset;
    .ant-tooltip-content > .ant-tooltip-inner {
      white-space: nowrap;
    }
  }
}

.explanation-bubble {
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 0 10px #0003;
  padding: 10px;
  .btn-transparent > svg {
    margin: 0px;
  }
  &__text {
    flex-grow: 2;
    width: 100%;
  }
}

.qualification-invite-info-tooltip {
  min-width: 350px;
}
