.our-suppliers {
  .content-block_header {
    position: relative;
    svg {
      width: 40px;
      height: 36px;
    }
    @include for-size(sm) {
      align-items: center;
      margin-bottom: 40px;
    }
  }
  &.content-block_wrapper {
    .monitoring-tab-tooltip {
      margin: 0px 30px 6px -25px !important;
    }
    .monitoring-tab-tag {
      margin: 0px 30px 6px -20px !important;
    }
    .qualifying-tab-counter {
      margin: 0px 0px 7px -25px !important;
    }
  }
  &_explanation {
    position: absolute;
    right: 0;
    @media only screen and (max-width: 1260px) {
      display: none;
      visibility: hidden;
    }
  }
  @include for-size(sm) {
    .find-suppliers__search-select {
      padding: 20px 0px;
    }
  }
  .find-suppliers__language {
    @include for-size(sm) {
      .custom-title {
        margin-left: 10px;
      }
      .custom-switch_wrapper > button {
        margin-left: 10px !important;
      }
    }
  }
  &__organizations-table {
    position: relative;
    .organization-card_head__name .ant-select-selection-item,
    .organization-card_head__indicator.__rating,
    .organization-card_head__indicator.__answers {
      font-weight: 700;
    }
    .organization-card_head__indicator.__rating,
    .organization-card_head__indicator.__answers {
      font-size: 12px;
    }
    .subscription-upgrade-modal {
      &__wrapper {
        top: 40px;
        right: 10px;
      }
    }
  }
  .tabs-wrapper {
    display: flex;
    flex-direction: row;
    .info {
      display: flex;
      flex: 2;
      justify-content: flex-end;
    }
  }
  .monitoring {
    display: flex;
    justify-content: center;
    padding: 80px 0;
    &.empty {
      display: flex;
      align-items: center;

      .illustration {
        position: relative;
        width: 200px;
        height: 200px;
        margin-right: 40px;
        .illustration-cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .illustration-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .instruction {
        width: 40%;
        max-width: 370px;

        .heading {
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          margin: 0 0 10px 3px;
        }

        .text {
          font-family: Caveat, Lato, sans-serif;
          font-size: 24px;
          line-height: 1.2;
        }
      }
    }
    &.disabled {
      align-items: center;
      flex-direction: column;
      align-items: center;
      .top {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .illustration {
          margin-right: 40px;
          position: relative;
          .illustration-bg {
          }
          .illustration-moving {
            width: 180px;
            height: 180px;
            position: absolute;
            top: 20px;
            left: 20px;
            animation: rotate 6s infinite linear;
          }
        }
      }
      h1 {
        font-size: 36px;
        font-weight: 900;
        margin-bottom: 0;
      }
      h4 {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        align-self: flex-start;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          display: inline-flex;
          align-items: center;
          list-style-type: none;
          padding: 0;
          margin-right: 20px;
          &::before {
            content: "•";
            font-size: 8px;
            margin-right: 5px;
          }

          svg {
            margin-right: 5px;
          }
        }
        &.smaller-text {
          li {
            font-size: 12px;
          }
        }
      }
    }
    a {
      display: inline-flex;
      align-items: center;
      padding: 20px 20px 20px 20px;
      border-radius: 3px;
      background: $purple;
      color: #ffffff;
      text-decoration: none;
      margin-top: 20px;
      white-space: nowrap;
      &:hover {
        background: $blue;
      }
    }
  }
}
