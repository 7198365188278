.badge {
  margin-left: auto !important;
  sup {
    background-color: $red;
    display: flex !important;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    span {
      display: block;
      font-size: 10px;
      font-weight: 700;
    }
  }
  &.gray > sup {
    background-color: $discrete-text;
  }
}

.text-badge {
  font-size: 10px !important;
  border-radius: 3px;
  padding: 3px !important;
  &.purple {
    color: $purple;
    border: 1px solid $purple !important;
  }
  &.green {
    color: $green;
    border: 1px solid $green !important;
  }
  &.red {
    color: $red;
    border: 1px solid $red !important;
  }
  &.orange {
    color: $orange;
    border: 1px solid $orange !important;
  }
  &.__small {
    height: 20px;
    padding: 1px 3px !important;
  }
}

.initials-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  min-width: 20px;
  font-weight: 700;
  font-size: 8px !important;
  line-height: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  border: 1px solid $white;
  text-align: center;
  user-select: none;
  position: relative;

  &.__large {
    height: 40px;
    width: 42px;
    min-width: 42px;
    font-size: 16px !important;
    line-height: 36px;
    border: 2px solid $white;
    margin-left: 3px;
  }

  &.__huge {
    height: 80px;
    width: 80px;
    min-width: 80px;
    font-size: 32px !important;
    line-height: 76px;
    border: 2px solid $white;
  }

  &.current-user-initials {
    background-color: $green;
    color: $white !important;
  }
  & > .icon-clear {
    position: absolute;
    display: none;
  }
  &.show-custom-icon:hover {
    font-size: 0;
    background-color: $white;
    & > .icon-clear {
      display: block;
    }
  }
}

.notifier-dot {
  display: inline-flex;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  .static {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $red;
  }

  .pulser {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $red;
    animation: pulse 2s ease 0s infinite normal none;
    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
  }
}

.handy-pointer-blob {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-family: caveat;
  font-size: 16px;
  font-weight: 200;
  &.absolute,
  &.relative {
    top: var(--top, auto);
    right: var(--right, auto);
    bottom: var(--bottom, auto);
    left: var(--left, auto);
  }
  &.absolute {
    position: absolute;
  }
  &.relative {
    position: relative;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.align-right {
    justify-content: flex-end;
  }
  .newbadge,
  .newsbadge {
    display: flex;
    padding: 3px 2px;
    margin-right: 10px;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: bold;
    border: 1px solid $red;
    border-radius: 3px;
    color: $red;
  }

  .newsbadge {
    font-family: "Lato";
  }
  .scribble {
  }

  svg {
    margin: 0 6px 0 0;
    &.show-last {
      margin: 0 0 0 6px;
      order: 1;
    }
    &.show-first {
      margin: 0 6px 0 0px;
      order: -1;
    }
    &.wiggly-animation {
      animation: point 3s ease-in-out 0s infinite alternate-reverse;
      @keyframes point {
        0% {
          transform: rotate(30deg);
        }

        100% {
          transform: rotate(-60deg);
        }
      }
    }
    &.pointy-animation {
      position: relative;
      animation: MoveUpDown 3s ease-in 3;
      @keyframes MoveUpDown {
        0% {
          top: 0;
        }
        20% {
          top: -40px;
        }

        40% {
          top: 0;
        }

        100% {
          top: 0;
        }
      }
    }
  }
  @media (max-width: 575px) {
    display: none;
    visibility: hidden;
  }
}
