.email-suggest-company-modal {
  &__wrapper {
    display: flex;
    flex-direction: row nowrap;
    align-items: center;
    padding: 0px;
    background-color: transparent;
    margin: 0px;
    box-shadow: none;
    top: -150px;
    left: -10px;
    z-index: 10;
    min-width: 470px !important;
    max-width: 960px !important;
  }
  &__left-line {
    min-width: 60px;
    border: 1px solid $purple;
  }
  &__inner {
    min-width: 410px !important;
    max-width: 900px !important;
    padding: 20px;
    border: 1px solid $purple;
    border-radius: 3px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.12);
    background-color: $white;
    display: flex;
    flex-flow: column nowrap;
    & > .__one-row,
    &_description > .__one-row {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    button.btn-transparent {
      &:first-child {
        width: calc(100% - 110px);
      }
      &:last-child {
        white-space: nowrap;
      }
      & > svg {
        flex-shrink: 0;
      }
      & > span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
