@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .settings-page {
    &_wrapper {
      padding-bottom: 0px;
    }
  }
}

@media (min-width: 320px) and (max-width: 991.98px) {
  .settings-page {
    &__info {
      margin-top: 70px;
    }
    &__title {
      margin-bottom: 20px;
      .custom-title {
        margin-bottom: 15px;
      }
    }
    &__form {
      .custom-control.custom-switch {
        margin: 20px 0px 10px 0px;
      }
    }
  }
}
