.ant-drawer {
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-content {
    background: $off-white;
  }
}

.drawer-component-content {
  .ant-drawer-content-wrapper {
    width: 95% !important;
    @include for-size(sm) {
      width: 100% !important;
    }
  }
  &.admin_header__drawer {
    .ant-drawer-content-wrapper {
      width: 50% !important;
      min-width: 280px;
    }
  }
}
