.contact-person {
  &__wrapper {
    padding: 10px;
    border-radius: 3px;
    width: fit-content;
    &.with-background {
      background-color: $light-white;
      max-width: -webkit-fill-available;
    }
  }
  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.message-panel {
  &_wrapper {
    display: flex;
    cursor: default;
  }

  &_create-mode {
    &_wrapper {
      width: 100%;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      padding: 10px 10px 0px 10px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      .textarea {
        resize: none;
        width: 100%;
        outline: none;
        border: none;
      }
      .tools {
        display: flex;
        cursor: default;
        &-upload {
          span.ant-upload {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  &_view-mode {
    &_wrapper {
      background-color: $white-gray;
      border-radius: 3px;
      padding: 20px;
      width: 100%;
      .tools {
        display: flex;
        align-items: center;
      }
    }
    &_date {
      color: $mid-gray;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.custom-collapse {
  & > .ant-collapse-item {
    & > .ant-collapse-header {
      padding: 0px !important;
      align-items: center;
    }
    & > .ant-collapse-content {
      display: none;
    }
  }
}
