.modal-settings {
  a {
    color: black;
  }

  &__wrapper {
    background: $off-white;
    margin-left: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    @include for-size(sm) {
      padding: 20px;
    }

    .btn-custom_close-modal {
      display: block;
    }
  }

  &__content {
    width: 100%;

    button {
      text-transform: none;
    }
    .tab {
      @include for-size(md) {
        text-align: left;
        margin-bottom: 20px;
        font-size: 13px;
      }
      &__active {
        font-weight: normal;
      }
    }
  }

  &__tabs {
    margin-bottom: 80px;
    padding: 0 40px;
    display: flex;
    @include for-size(md) {
      display: flex;
      margin-bottom: 30px;
    }
    @include for-size(sm) {
      padding: 20px;
    }

    .tab {
      font-weight: 400;
      display: flex;
      align-items: center;
      position: relative;
      &:before {
        position: absolute;
        content: "";
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
        border-radius: 50px;
        background-color: $link;
        opacity: 0;
      }
      img {
        margin-right: 15px;
      }
      svg {
        margin-right: 15px;
        margin-bottom: -5px;
      }
      p {
        @include for-size(sm) {
          display: none;
        }
      }
      &__active {
        border-bottom-color: transparent !important;
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &__close-modal {
    border: none;
    background: transparent;
    cursor: pointer;
    img {
      @include for-size(md) {
        width: 15px;
        object-fit: contain;
      }
    }
  }

  .tab-active {
    background: $link;
    width: 78%;
    height: 2px;
    display: block;
    margin: 14px 0 0 0;
  }
}
