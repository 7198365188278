.our-requests {
  background: $white;
  margin-bottom: 40px;
  border-radius: 3px;
  &-table-title {
    margin-top: 50px;
  }
  .empty-data {
    text-align: center;
    font-weight: 700;
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      padding: 40px 45px;
      svg {
        margin: 0px 20px 0px 0px;
      }
    }
    &_wrapper {
      min-height: 50vh;
    }
    &_title {
      line-height: 31px;
    }
  }
  &_head__btn {
    margin-left: auto;
  }

  .empty-zone_wrapper {
      background-color: $off-white;
      padding: 45px;
      span {
        font-size: 16px;
      }
  }
  .tags-and-projects-zone {
    display: flex;
    &__manage-projects {
      margin-left: auto;
    }
  }
}
