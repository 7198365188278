.stripe-page {
  width: 50%;

  h1 {
    font-size: 50px;
    margin-bottom: 20px;
    @include for-size(md) {
      font-size: 40px;
    }
  }

  &__header {
    .header {
      @include for-size(md) {
        flex-direction: column;
      }
    }
  }
}
