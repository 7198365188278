.footer {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  &__info {
    display: flex;
    padding: 40px 40px 80px 40px;
    justify-content: center;
  }
  .chunk {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__links {
    width: fit-content;
    margin-right: 60px;
    &:last-child {
      margin-right: 0;
    }
    display: flex;
    flex-direction: column;
    @include for-size(md) {
      width: 100%;
      margin-bottom: 20px;
    }
    a {
      color: $black;
      line-height: 130%;
      margin-bottom: 10px;
    }

    .support-title {
      font-weight: bold;
      font-size: 12px;
      line-height: 1;
      text-transform: uppercase;
    }

    .a-link {
      white-space: nowrap;
      line-height: 1;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 20px;
        height: auto;
      }
    }

    .email {
      * {
        align-self: baseline;
      }
      svg {
        top: 2px;
        position: relative;
      }
    }

    svg {
      display: inline-block;
    }
  }
}
