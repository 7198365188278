.organization-page.content-block_wrapper {
  overflow: hidden;
  padding-right: 0;
}

.contact-person {
  &_wrapper {
    display: flex;
    overflow-x: scroll;
    padding: 10px;
    margin: 10px 0 10px -10px;
  }

  &_item {
    min-width: 200px;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    line-height: 1.2;
    border-radius: 3px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    background-color: $white;
    white-space: nowrap;
    font-size: 12px;
    cursor: pointer;
    &.__fit-content {
      max-width: fit-content;
    }
    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      height: 16px;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    span {
      color: $dark-grey;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      margin: 0px 11px 0px 2.5px;
      min-width: 16px;
      width: 16px;
    }
    &.__black-text span {
      color: $black;
    }
    &__name {
      height: auto !important;
      margin: -5px 0px 0px -5px !important;
      padding: 5px;
      font-weight: 700;
    }
    &__work-info {
      min-height: 30px;
      padding: 5px 0px;
      margin: 10px 0px -5px 0px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      & > svg {
        margin-right: 5px;
      }
      & > .ant-tag.custom-tag {
        height: 20px !important;
        line-height: 18px;
        padding: 0px 5px;
        font-size: 11px;
      }
    }
  }
}
