.framework-agreements {
  .content-block_header {
    @include for-size(sm) {
      align-items: center;
      margin-bottom: 40px;
    }
  }
  @include for-size(sm) {
    .find-suppliers__search-select {
      padding: 20px 0px;
    }
  }

  &__null-state {
    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
    &-left-side {
      flex-grow: 2;
      padding: 40px;
    }
    &-right-side {
      position: relative;
    }
    &-content {
      width: 582px;
      height: 582px;
    }

    &-frame {
      position: absolute;
      left: 0;
    }
    &-item {
      &-title {
        display: flex;
        align-items: center;
        font-weight: 700;
        margin-bottom: 20px;
      }
      &-description {
        margin-bottom: 20px;
      }
      &-attention {
        font-style: italic;
        margin-bottom: 20px;
      }
    }
  }

  &__organizations-table {
    .requests-card {
      &_head,
      &_body {
        &__name {
          width: 70%;
        }
        &__column {
          width: 10%;
          text-align: right;
        }
      }
      &_head {
        padding: 20px 0px;
        margin: 0px;
        &__name {
          .ant-select-selection-item {
            font-weight: 700;
          }
        }
      }
      &_body {
        cursor: default;
        margin: 0px;
        padding: 10px 0px;
        border: none;
        border-radius: 0px;
        &:hover {
          border: none;
          color: unset;
        }
        &__wrapper {
          padding: 10px 0px 0px 0px;
          &:not(:nth-child(2)) {
            border-top: 1px solid $search-border;
          }
        }
        &__name {
          display: flex;
          &.__title {
            min-width: 40%;
          }
          &.__ramavtal {
            display: block;
            min-width: 60%;
            padding-left: 40px;
            .editBlock_upload__item.__row-view {
              margin: 0px;
              padding: 0px;
            }
          }
          &:hover {
            color: $black;
          }
        }
        &__column {
          font-size: 12px;
          color: $discrete-text;
          &:hover {
            color: $discrete-text;
          }
        }
      }
    }
  }
}
