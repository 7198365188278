.find-suppliers {
  margin-bottom: 40px;
  border-radius: 3px;
  a {
    color: $link;
  }
  &__language {
    margin-bottom: 20px;
  }

  &_tabs {
    &__wrapper {
      margin-bottom: 15px;
      @include for-size(md) {
        margin-top: 20px;
      }
      .ant-tabs {
        overflow: visible;
        &-top {
          .ant-tabs-nav {
            margin-bottom: -1px;
          }
        }
        &-nav {
          &:before {
            display: none;
          }
        }
        &-ink-bar {
          display: none;
        }
        &-tab {
          margin: 0 !important;
          padding: 20px;
          font-size: 12px;
          color: #707070;
          line-height: 1;
          text-transform: uppercase;
          font-weight: 700;
          border-radius: 3px 3px 0 0;
          &:hover {
            color: $black;
          }
          &-active {
            font-weight: 500;
            background: $white;
            border-width: 1px 1px 0 1px;
            border-style: solid;
            border-color: #f1f1f1;

            .ant-tabs-tab-btn {
              color: $black !important;
              font-weight: 700 !important;
            }
          }
          &-disabled {
            color: $black !important;
            opacity: 0.15;
            cursor: default !important;
            &:hover {
              opacity: 0.15;
            }
          }
        }
        &-content-holder {
          padding: 20px;
          border-radius: 0 3px 3px 3px;
          background: $white;
          border: 1px solid #f1f1f1;
        }
      }
    }
  }

  &_tab {
    &__item {
      outline: none !important;
      .first-setup_selection__item-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        outline: none !important;
        .multi-select {
          margin-right: 50px;
          .ant-select.ant-select-multiple {
            min-height: 42px;
          }
          .ant-select-selector {
            background-color: transparent;
          }
        }
      }
    }
  }

  &__search-select {
    padding: 0 0px 20px 0px;
    position: relative;
  }
  .external-report-explainer {
    display: flex;
    align-items: center;
    @media (max-width: 1250px) {
      display: none;
      visibility: hidden;
    }
  }
  &__inp-name {
    margin-left: 40px;
    display: flex;
    @include for-size(md) {
      margin-left: 10px;
      flex: 1 1;
    }
    label {
      font-size: 14px;
      color: $black;
      margin-bottom: 20px;
      line-height: 1.25;
    }
    input {
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      width: 275px;
      height: 40px;
    }
  }
}
