// $font-family-sans-serif: "Nunito", sans-serif;

// Colors

$black: #000000;
$blue: #1ba2cc;
$mid-gray: #a9a9a9;
$light-gray: #d8d8d8;
$dark-grey: #3e3e3e;
$white-gray: #efefef;
$white: #ffffff;
$gold: #ffce54;
$dimmed-opaque-white: rgba(255, 255, 255, 0.6);
$off-white: #fafafa;
$light-white: #f5f5f5;
$discrete-text: #626262;
$red: #ba2e2e;
$pink: #e1d3d3;
$green: #6bc069;
$white-green: #f0f9f0;
$orange: #c17e2e;
$purple: #ac4dc4;
$light-yellow: #ffff84;
$light-blue: #e8f6fa;
$icon-gradient: linear-gradient(180deg, #1ba2cc 0%, #6bc069 100%);
$link: $blue;
$link-opaque-bg: rgba(27, 162, 204, 0.05);
$link-hover: rgba(27, 162, 204, 0.1);
$search-border: #e2e2e2;
$form-border: #ebebeb;
$blue-less-opaque: rgba(27, 162, 204, 0.19);
$green-less-opaque: rgba(107, 192, 105, 0.19);
$green-light-opaque: rgba(107, 192, 105, 0.1);
$red-light-opaque: rgba(186, 46, 46, 0.1);
$black-light-opaque: rgba(0, 0, 0, 0.03);
