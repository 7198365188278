.hd-content-block_header {
  &.__wrapper {
    padding: 40px 40px 30px 40px;
    background-color: #fff;
    border-radius: 3px 3px 0 0;
    @include for-size(sm) {
      padding: 20px 10px 30px 20px;
    }
  }
  &.__inner-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &.__title {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 0;
  }
  &.__subtitle {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: $mid-gray;
  }
  .contract-status {
    font-size: 14px;
    display: flex;
    font-weight: normal;
    margin-left: 40px;
    svg {
      margin-right: 5px;
    }
  }
  &.__date {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    line-height: 1;
    text-align: right;
    flex-direction: column;
    &.stopped {
      color: $mid-gray;
    }

    .open-closed {
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 5px;
      color: $mid-gray;
      span {
        &.__closed-offer {
          color: $red;
        }
      }
    }
  }

  .statuses {
    font-size: 10px;
    text-transform: uppercase;
  }

  &.__interest-status {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > .btn-custom {
      &_main {
        height: 32px;
        font-size: 12px;
        padding: 5px;
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }

  .open-rft-explainer {
  }

  &.__left-block {
    max-width: 50%;
  }
  &.__right-block {
    display: flex;
    align-items: center;
    .add-rft-to-calendar {
      margin-left: 20px;
      display: flex;
      align-items: center;
      button {
        margin-left: 10px;
        & span > svg > path {
          fill: $blue;
        }
      }
    }
    .share-feature {
      margin-left: 20px;
    }
  }
  .our-response {
    flex-direction: column;
  }
  &.__assignee-select {
    .select .ant-select.__show-arrow .ant-select-selector {
      box-shadow: none !important;
      border: 1px solid #828282 !important;
      padding-left: 6px;
      & > span {
        padding-left: 0px;
      }
    }
    .ant-select-arrow > svg {
      margin: 2px 0px 0px -5px;
    }
    .ant-select-selection-placeholder {
      color: #828282;

      svg {
        margin-right: 10px !important;
        & > path {
          fill: #8a8b46;
        }
      }
    }
  }
}
.guided-timeline {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  font-size: 10px;
  text-transform: uppercase;
  .step-label {
    margin: 0 0 5px 25px;
  }
  .timeline-step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-child) {
      margin-right: -5px;
    }
    &:last-child {
      .line-container {
        .dot {
          margin-left: -1px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .line-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    height: 12px;
    .dot {
      display: flex;
      align-items: center;
      height: 12px;
      width: 12px;
    }
    .line {
      height: 5px;
      flex: 1 0 0;
    }
  }
}
