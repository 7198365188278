.qualifications-questions-modal {
  &__wrapper > .modal-ent__content > div {
    padding-top: 0px !important;
  }
  &__questions {
    max-height: 340px;
    overflow-y: auto;
    border-radius: 3px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12) inset;
    padding: 20px;
  }
  &__question-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px;
    background-color: $off-white;
    border-radius: 3px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .ant-upload.ant-upload-select {
      & > span.ant-upload {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        a {
          font-size: 12px;
          color: $link;
        }
      }
    }
  }
}
.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .infolink {
    display: flex;
    flex: 2;
    justify-content: flex-end;
  }
}

.qualifications-answers-modal {
  &__wrapper > .modal-ent__content > div {
    padding-top: 0px !important;
  }
  &__users {
    display: flex;
    &_sent {
      width: 70%;
    }
    &_submit {
      width: fit-content;
      max-width: 30%;
      margin-left: auto;
    }
  }
  &__answers {
    max-height: 340px;
    overflow-y: auto;
    border-radius: 3px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12) inset;
    padding: 20px;
  }
  &__answer-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 20px;
    background-color: $off-white;
    border-radius: 3px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    span.tools-upload span.ant-upload {
      display: flex;
    }
    a {
      font-size: 12px;
      color: $black;
      text-decoration: underline;
    }
  }
}
