.modal-email-exists {
  padding: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__handybeaver {
    height: 390px;
    width: auto;
  }
  &__info {
    width: 100%;
    text-align: left;
  }
  &__description,
  &__action {
    font-size: 16px;
  }
  &__action {
    margin: 20px 0px 10px 0px;
  }
  &__notification {
    font-size: 12px;
  }
  &__favorites-button.btn-custom_main.__large {
    padding: 20px 40px !important;
  }
}
