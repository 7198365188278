.our-evaluation-section {
  &_wrapper {
    padding-left: 10px;
    .presentation-card__header {
      margin-bottom: 0;
    }
    .presentation-card__content {
      .sub-heading {
        display: flex;
        align-items: center;
      }
    }
    .presentation-card {
      margin-bottom: 20px;
    }
    .notes_wrapper {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      .textarea {
        &__edit {
          min-height: 191px;
        }
        &__view {
          padding: 20px;
          width: 100%;
          min-height: 224px;
        }
      }
      .form-wrapper {
        width: 100%;
        .save-btn {
          margin-left: auto;
        }
      }
    }
    .info-chunk {
      margin-bottom: 40px;
    }

    .message-card {
      &__messages-list {
        padding: 3px 0px 0px 0px;
      }
    }
  }
}

.rating-observer {
  &_wrapper {
    margin-top: 30px;
    .rating-stars_wrapper {
      margin-bottom: 15px;
      span {
        img {
          width: 36px;
          height: auto;
        }
      }
    }
  }
  &_head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &__btn {
      position: relative;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      font-size: 14px;
      cursor: pointer;
      padding: 15px 35px 15px 15px;
      transition: 0.2s;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        right: 0;
        width: 12px;
        height: 12px;
        border-bottom: 1px solid $black;
        border-right: 1px solid $black;
        transform: translateY(-60%) rotate(45deg);
        transition: 0.2s;
      }
      &.__collapse {
        &:before {
          transform: translateY(-10%) rotate(-135deg);
        }
      }
    }
  }
  &_body {
    &__wrapper {
      margin-bottom: 40px;
      border-bottom: 1px dotted $light-gray;
    }
  }
  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__wrapper {
      margin-bottom: 40px;
    }
    &__edit {
      margin-bottom: 20px;
      padding: 10px 0 20px;
      border-top: 1px dotted $light-gray;
      border-bottom: 1px dotted $light-gray;
      .rating-observer_content {
        max-width: 220px;
        .rating-observer_stars__wrapper {
          margin-bottom: 10px;
        }
        .rating-observer_input-wrap {
          margin-bottom: 10px;
        }
        .ant-input {
          padding: 7px 10px;
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  &_title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0;
    text-transform: uppercase;
  }
  &_text {
    font-size: 16px;
    margin-bottom: 0;
  }
  &_actions {
    display: flex;

    button {
      margin-right: 20px;
    }
  }
}

.add-rating {
  &_wrapper {
    margin-bottom: 40px;
  }
  &_field-wrap {
    margin-bottom: 10px;
    width: 220px;
    .ant-input {
      width: 100%;
      height: 38px;
      padding: 10px;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.text-box {
  &_wrapper {
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: #fff;
    padding: 20px;
    p {
      font-size: 14px;
      color: $black;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}
