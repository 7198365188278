.input {
  height: 60px;
  background: $white;
  border: none;
  font-size: 14px;
  line-height: 17px;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  outline: none;
  padding-left: 20px;
  padding-right: 10px;

  //::-webkit-input-placeholder {
  //  font-weight: normal;
  //  color: $placeholder;
  //}
  &.disabled {
    background-color: $light-gray;
  }
  &.__small {
    height: 40px;
  }
}

.form.bg-yellow > input {
  background-color: $light-yellow !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.inputs-code {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 40px;
  margin-bottom: 40px;

  .form {
    width: 60px;
    margin-right: 10px;
    @include for-size(xs) {
      width: 60px;
      input {
        font-size: 16px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    input {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
  }
}

.form-error {
  width: fit-content;
  font-size: 12px;
  background: $red;
  color: $white;
  border-radius: 3px;
  padding: 3px 8px;
  margin-top: 5px;
  margin-left: 0px;
  border-radius: 3px;
}

.label {
  font-size: 14px;
  line-height: 130%;
  display: block;
  margin: 20px 0 10px 0px;
}

.form-field_wrapper {
  margin-bottom: 20px;
  position: relative;

  & > label {
    display: block;
    margin-bottom: 10px;
    &.label-data-mode {
      font-size: 14px;
      color: $mid-gray;
      font-weight: 700;
      text-transform: uppercase;
    }
    .label-description {
      font-size: 13px;
      color: $black;
      font-weight: 400;
      text-transform: none;
      margin: 15px 0px 10px 0px;
    }
  }

  & > .ant-input {
    margin-bottom: 5px;
    width: 100%;
    height: 60px;
    min-width: 130px;
    padding-left: 20px;
    background: #ffffff;
    border: none;
    font-size: 14px;
    line-height: 17px;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    outline: none;

    &:focus,
    .ant-input-focused {
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .ant-input-affix-wrapper.input {
    border: none;
    outline: none;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 0;
    align-items: center;

    &:focus,
    .ant-input-focused {
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    }

    input.ant-input {
      margin: 5px;
      padding-left: 11px;
      background-color: transparent;
    }

    .ant-input-prefix svg {
      margin-right: -10px;
      margin-left: 10px;
    }

    .ant-input-suffix svg {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  &.resize-field {
    & > input {
      width: 100%;
    }

    .aux-field {
      padding: 0px 12px;
      height: 0px;
      visibility: hidden;
    }
  }

  .textarea-counter {
    position: absolute;
    top: -16px;
    right: 0;
    color: $mid-gray;
    font-size: 10px;
    &.__with-label {
      top: 16px;
    }
  }

  textarea {
    resize: none;
    height: unset !important;
  }

  &.location-input {
    .modal-maps__wrapper {
      width: 270% !important;
      height: 300px;
      @include for-size(xs) {
        left: -10%;
        width: 120%;
        height: 300px;
      }
    }
    input {
      width: 200px;
      text-overflow: ellipsis;
    }
    &.__map-bottom-center {
      .modal-maps__wrapper {
        left: -100%;
      }
    }
  }

  &.__full-width {
    width: 100%;
  }
  &.__small {
    .ant-input-affix-wrapper.input,
    input {
      height: 38px;
    }
    & > div > .ant-input-affix-wrapper > input.ant-input,
    & > div > .ant-input-affix-wrapper-focused > input.ant-input {
      background-color: transparent;
    }
    &:not(.location-input-autocomplete)
      > div
      > .ant-input-affix-wrapper
      > input.ant-input,
    &:not(.location-input-autocomplete)
      > div
      > .ant-input-affix-wrapper-focused
      > input.ant-input {
      height: 38px;
      padding-left: 20px;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25) !important;
      padding: 4px 10px 4px 20px;
    }
  }
  &.__tiny {
    .ant-input-affix-wrapper.input,
    input {
      height: 32px;
      font-size: 12px;
    }
  }
  &.__uppercase {
    .ant-input-affix-wrapper.input,
    input {
      text-transform: uppercase;
    }
  }
  &.__bold {
    .ant-input-affix-wrapper.input,
    input {
      font-weight: 700;
    }
  }
  &.__mark-unfilled {
    .ant-input-affix-wrapper.input,
    .ant-input-suffix {
      background-color: $light-yellow !important;
    }
  }
  &.__mark-unfilled {
    .ant-input-suffix {
      margin-right: 2px;
    }
  }
}

.standard-input {
  &.__small {
    label {
      font-size: 12px;
    }
    input.input {
      font-size: 12px;
      height: 36px;
    }
  }
  &.__tiny {
    label {
      font-size: 12px;
    }
    input.input {
      font-size: 12px;
      height: 32px;
    }
  }
  &.__bold > input.input {
    font-weight: 700;
  }
  &.__uppercase > input.input {
    text-transform: uppercase;
  }
  &.__flat-view > input.input {
    box-shadow: none;
    border: 1px solid $light-gray;
    border-radius: 3px;
  }
  &.__full-width {
    width: 100%;
    & > input.input {
      width: 100%;
    }
  }
}

// google-maps dropdown
.pac-container.pac-logo {
  font-family: Lato;
  margin-top: 4px;
  border: none;
  width: 250px !important;
  &::after {
    display: none;
  }
}
