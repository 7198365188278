.counter-strip {
  &_wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__counter {
    &_wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-right: 20px;
    }
    &_value {
      margin: 0px 2px 0px 10px;
    }
    &_description {
      text-transform: lowercase;
      white-space: nowrap;
    }
  }
}