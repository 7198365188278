.main-page {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    background: $off-white;
    .logo-language {
      width: 280px;
      margin-bottom: 40px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      @include for-size(sm) {
        width: 100%;
        .header__logo {
          width: 155px;
          height: 30px;
        }
      }
      .language {
        box-shadow: none;
        width: 30px;
        height: 20px;
        overflow: hidden;

        .ant-select-selector {
          width: 30px;
          height: 20px !important;
          padding: 0 !important;
        }

        .ant-select-selection-item {
          display: none;
        }

        svg {
          left: 0;
        }
      }
    }
  }
  &__content {
    margin-top: 40px;
    text-align: center;
    .top {
      padding-top: 40px;
      background: $off-white;
      position: relative;
      padding-bottom: 40px;
      box-shadow: 0px 10px 8px -9px rgba(0, 0, 0, 0.08);
      @include for-size(md) {
        padding: 0 20px 40px 20px;
      }
      @include for-size(xs) {
        p {
          font-size: 16px;
        }
      }

      .header__logo-wrapper {
        justify-content: center;
      }

      h2 {
        font-weight: 900;
        font-size: 40px;
        line-height: 130%;
        margin: 39px 0 30px 0;
        @include for-size(md) {
          font-size: 23px;
          margin: 20px 0;
        }
      }

      p {
        opacity: 0;
        animation: fadeIn 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2s both;
      }

      .sms-extra-info {
        color: $mid-gray;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 700;
      }
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      button {
        margin: 40px 0 60px 0;
        width: 280px;
        padding: 0;
        @include for-size(md) {
          margin: 25px 0 84px 0;
        }
        @include for-size(sm) {
          margin: 25px 0 28px 0;
        }
        @include for-size(xs) {
          width: 100%;
          padding: 10px;
          height: 51px;
        }
      }
    }

    .bottom {
      padding: 80px 20px;

      background: grayscale($color: #e4e4e4);

      h4 {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
      }

      iframe {
        width: 400px;
        height: 225px;
        @include for-size(sm) {
          width: 80vw;
          height: 44vw;
        }
        box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5),
          0px 0px 12px 8px rgba(0, 0, 0, 0.18);

        border-radius: 3px;
      }

      .instructions {
        opacity: 0;
        animation: fadeIn 1s cubic-bezier(0.39, 0.575, 0.565, 1) 4s both;
        display: flex;
        width: min-content;
        margin: 0 auto;
        border-top: 1px solid #dcdcdc;
        padding: 20px 0;
        @include for-size(md) {
          flex-direction: column;
        }

        .column {
          white-space: nowrap;
          text-align: left;
          &:not(:last-child) {
            margin-right: 40px;
          }
          @include for-size(md) {
            &:not(:last-child) {
              margin-right: 0;
              margin-bottom: 20px;
            }
          }

          ol {
            padding: 0;
            list-style-position: inside;

            li {
              &:not(:last-child) {
                margin-bottom: 5px;
              }

              * {
                vertical-align: middle;
              }

              svg {
                margin-left: 5px;
              }
            }

            li::marker {
              font-weight: bold;
            }
          }

          p {
            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .capcha-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 18px;
    }

    &__form {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 20px;
      @include for-size(xs) {
        justify-content: space-around;
      }
    }

    .react-tel-input {
      .form-control {
        @include for-size(xs) {
          width: 100%;
          font-size: 18px;
        }
      }
    }

    .react-number-input {
      height: 60px;
      width: 280px;
      background: $white;
      border: none;
      font-size: 14px;
      line-height: 17px;
      border-radius: 3px;
      outline: none;
      display: flex;
      align-items: flex-end;
      @include for-size(xs) {
        width: 100%;
      }
      .form-control {
        @include for-size(s) {
          width: 100%;
        }
      }
      .country-list {
        text-align: left;
        width: 285px;
      }
    }

    .react-number-input-search {
      height: 60px !important;
      border: none !important;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      &:focus {
        outline: none;
      }
    }

    .react-number-btn {
      border: none !important;
      background: transparent !important;
    }

    .selected-flag {
      background: transparent !important;
      padding-left: 17px !important;
      .arrow {
        display: none;
      }
    }
  }
}
