.identify-owner {
  &__wrapper {
  }
  &__container {
    max-width: 450px;
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      margin-bottom: 0;
    }

    .smaller {
      font-size: 12px;
      margin-top: 40px;
      text-align: center;
    }
  }
  &__title {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 24px;
      font-weight: 800;
    }
  }

  &__form {
    .field {
      margin-bottom: 40px;
      input {
        width: 100%;
      }
    }
    .btn-custom {
      &_wrap {
        display: flex;
      }
      &_main {
        min-width: 280px;
        height: 60px;
        justify-content: center;
      }
    }
  }
}
