.history-info-modal {
  &__content {
    padding: 40px;
  }
  &__table {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    max-height: 290px;
    overflow-y: auto;
    width: 100%;
    &-column {
      margin-right: 40px;
      flex-shrink: 1;
      display: flex;
      flex-flow: column nowrap;
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }
      & > div {
        height: 32px;
      }
      & > div:not(:first-child) {
        padding: 5px 0px;
        white-space: nowrap;
      }
      & > div.strikethrough:not(:nth-child(2)) {
        text-decoration: line-through;
      }
      &.has-overflow {
        overflow: hidden;
      }
      &:not(.has-overflow) {
        flex-shrink: 0;
      }
    }
    &-head {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
}
