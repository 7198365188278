.joining-organization {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    & > .btn-custom_main.btn-transparent {
      height: 24px;
      font-size: 14px;
    }
  }
  .submit-offer-info {
    background: rgba(193, 126, 46, 0.1);
    border-radius: 5px;
    width: 350px;
    padding: 20px;
    margin-bottom: 20px;
  }
  .organization-info {
    padding: 20px;
    border: 1px solid $form-border;
    border-radius: 3px;
    &__wrapper {
      width: 100%;
      &.has-rfp {
        width: 350px;
      }
    }
    &__contacts {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      &.has-rfp {
        flex-flow: column nowrap;
        align-items: flex-start;
      }
    }
    &__contact-card {
      padding: 0 0 10px 0;
      background-color: unset;
      border: none;
      box-shadow: none;
      margin-right: 20px;
      svg {
        height: 20px;
        width: 20px;
      }
      span {
        font-size: 14px;
      }
      .initials-badge {
        font-size: 8px;
      }
      & > div:not(:last-child) {
        margin-bottom: 10px !important;
      }
      &.rfp-info {
        padding: 0px;
        & > div:not(:last-child),
        & > section {
          margin-bottom: 5px !important;
        }
      }
    }
    &__message {
      border: 1px solid $light-gray;
      border-radius: 3px;
      padding: 10px;
      min-width: 200px;
      max-width: fit-content;
      font-style: italic;
    }
  }
  .rfp-info {
    flex-grow: 2;
    background-color: $white;
    border-radius: 3px;
    padding: 20px;
    margin-right: 20px;
    &__name {
      font-size: 24px;
      font-weight: 800;
      margin: 5px 0px;
    }
    &__subtitle {
      margin-top: 10px;
      margin-bottom: 2px;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: $mid-gray;
    }
    .load-document-column {
      &__wrapper {
        display: flex;
        flex-flow: column nowrap;
      }
      &__title > .btn-custom_main.btn-transparent {
        margin-left: unset;
        height: 28px;
        margin-left: 20px;
        border-color: $light-gray;
      }
      &__body {
        flex-grow: 2;
        min-height: unset;
      }
    }
  }
}
