.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 40px;
  &__logo-wrapper {
    display: flex;
    align-items: center;
  }

  &_btn__showSidebar {
    display: none;
  }

  &__logo {
    background: url("../../../images/svg/handy-day.svg") no-repeat;
    width: 155px;
    height: 30px;
    @include for-size(md) {
      width: 99px;
      height: 20px;
    }

    background-size: cover;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__user-links {
    a {
      font-size: 16px;
      color: $black;
      outline: none;

      &:first-child {
        &:after {
          display: block;
          border-right: 1px dotted $light-gray;
        }
      }
    }
  }

  &__profile-settings {
    &__wrapper {
      padding: 0;
    }
  }

  &_profile {
    &__wrapper {
      display: flex;
      align-items: center;

      .ant-select-selection-placeholder {
        color: $black;
      }
      .badge-messages-count > sup {
        margin-top: 0;
      }

      .video-instruction-link {
        height: 32px;

        @include for-size(md) {
          display: none;
        }
      }

      .language {
        box-shadow: none;
        width: 30px !important;
        margin-right: 20px;

        .ant-select {
          width: 30px;
          height: 20px;
          .ant-select-selector {
            padding: 0 !important;
            height: 20px !important;
          }
        }

        svg {
          left: 0;
        }

        .anticon {
          display: none;
        }
        .ant-select-selection-item {
          display: none;
        }
      }

      .upgrade-button {
        @include for-size(md) {
          span {
            display: none;
          }
          svg {
            margin-right: 0;
          }
        }
      }

      .btn-custom_main[disabled] {
        margin-right: 10px;
      }
    }
    &__divider {
      height: 41px;
      border-right: 1px dotted $light-gray;

      @include for-size(md) {
        display: none;
      }
    }
    &__select {
      &-wrapper {
        display: flex;
        align-items: center;
        height: 41px;
        padding: 0px 10px 0px 20px;
        margin-right: 20px;
        border-right: 1px dotted $light-gray;
        border-left: 1px dotted $light-gray;
      }

      .ant-select-selector {
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        padding-left: 0px !important;
      }
      .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        cursor: auto;

        input {
          cursor: auto;
        }
      }

      .ant-select-selection-placeholder {
        padding-right: 20px;
        margin-right: 30px;

        &:after {
          content: "" !important;
          display: inline-block !important;
          position: absolute;
          top: calc(50%);
          transform: translateY(-10px);
          right: 10px;
          width: 20px !important;
          height: 20px;
          background-image: url("/assets/images/svg/arrow-bottom.svg");
          background-repeat: no-repeat;
          background-position-y: center;
          visibility: visible !important;
        }
      }

      .ant-select-disabled {
        .ant-select-selection-placeholder {
          padding-right: 0;
          margin-right: 0;
          &:after {
            content: "" !important;
            width: 0 !important;
            height: 0;
            display: none !important;
            visibility: hidden;
          }
        }
      }
    }
    &__name {
      display: block;
      margin-right: 20px;
      padding: 5px 20px 5px 0;
      color: $link;
      text-transform: lowercase;
      border: 0;
      box-shadow: none;
      background-color: transparent;
      cursor: pointer;
      border-right: 1px dotted $light-gray;
    }
    &__message {
      cursor: pointer;
      background-color: $red;
      box-shadow: none;
      border: 0;
      color: #fff;
      border-radius: 50px;
    }
    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
      border: 0;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }
  }
}

.profile {
  display: flex;
  align-items: center;
  @include for-size(md) {
    align-items: flex-end;
    flex-direction: column;
    font-size: 11px;
  }
}

.first-setup-header {
  display: flex;
  justify-content: space-between;

  img {
    width: 155px;
    height: 30px;
    @include for-size(xs) {
      margin-bottom: 20px;
      width: 95px;
    }
  }

  &__info {
    display: flex;
    @include for-size(md) {
      flex-direction: column;
      p {
        margin: 0;
        font-size: 12px;
      }
    }

    p:first-child {
      border-right: 1px dotted $light-gray;
      padding-right: 20px;
      @include for-size(md) {
        border: none;
        padding: 0;
      }
    }

    p:last-child {
      margin-left: 20px;
      @include for-size(md) {
        margin: 0;
      }
    }
  }
}

.dropdown-messages {
  &__list > ul {
    padding: 10px !important;
  }
  &__row {
    border: 1px solid $white;
    border-radius: 3px;
    padding: 10px;
    &:hover {
      color: $link;
      background-color: $white !important;
      border: 1px solid $link;
      border-radius: 3px;
      .dropdown-messages__row-info > svg > path {
        fill: $link !important;
      }
      .dropdown-messages__row-time {
        color: $link;
        font-weight: 700;
      }
    }
    &-time {
      font-size: 10px;
      color: $mid-gray;
    }
    &-info {
      display: flex;
      align-items: center;
      font-size: 12px;
      &__organization {
        margin: 0px 5px;
      }
      &__title {
        // min-width: 280px !important;
        max-width: 16vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        height: 12px;
        width: 19px;
      }
    }
  }
}
