.profile-settings {
  display: flex;

  a {
    color: $link;
    @include for-size(md) {
      font-size: 12px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    @include for-size(md) {
      margin-right: 10px;
    }
    @include for-size(sm) {
      display: none;
    }

    span {
      color: $mid-gray;
      font-size: 10px;
      text-transform: uppercase;
      margin-left: auto;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    @include for-size(sm) {
      display: none;
    }
  }
}
