#root {
  min-height: 100vh;
}

.main-content {
  &_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    background: $off-white;
    .layout-content_wrapper {
      display: flex;
      flex: 1 0 auto;
      padding-right: 40px;
      padding-left: 30px;
    }
    .layout-content_inner-wrap {
      width: 100%;
      padding-left: 30px;
      overflow: hidden;
    }
    .footer {
      flex: 0 0 auto;
      margin-top: 40px;
    }
    &.hide-on-fullscreen-mode {
      max-height: 100vh;
      overflow: hidden;
      .footer__info {
        display: none !important;
      }
    }
  }
  &_inner__wrapper {
    display: flex;
    flex: 1 0 auto;
  }
}

.error-page_title {
  text-align: center;
  font-size: 3rem;
  padding: 3em;
}

.flex-wrapper {
  display: flex;
  &.space-between {
    justify-content: space-between;
  }
  &.center {
    justify-content: center;
  }
}
.__bold {
  font-weight: 700 !important;
}
.__normal {
  font-weight: 400 !important;
}
.__italic {
  font-style: italic;
}
.__uppercase {
  text-transform: uppercase !important;
}
.__nonecase {
  text-transform: none;
}
.__strikethrough {
  text-decoration: line-through;
}
.__text-right {
  text-align: right;
}
.__text-center {
  text-align: center;
}

.db {
  display: block;
}

.di {
  display: inline !important;
}

.dn {
  display: none !important;
}

.vhidden {
  visibility: hidden !important;
}

.df {
  @include flex();
}
.df-row {
  @include flex();
  flex-flow: row wrap;
}
.df-column {
  @include flex();
  flex-flow: column nowrap;
}
.df-column-center {
  @include flex();
  flex-flow: column nowrap;
  @include ai-center();
}
.df-column-end {
  @include flex();
  flex-flow: column nowrap;
  align-items: flex-end;
}
.df-column-start {
  @include flex();
  flex-flow: column nowrap;
  align-items: flex-start;
}
.df-row-start {
  @include flex();
  flex-flow: row nowrap;
  align-items: flex-start;
}
.df-row-center {
  @include flex();
  flex-flow: row nowrap;
  @include ai-center();
}
.df-row-baseline {
  @include flex();
  flex-flow: row nowrap;
  align-items: baseline;
}
.df-row-wrap-center {
  @include flex();
  flex-flow: row wrap;
  @include ai-center();
}
.df-row-end {
  @include flex();
  flex-flow: row nowrap;
  align-items: flex-end;
}
.df-row-jcc-center {
  @include flex();
  flex-flow: row nowrap;
  justify-content: center;
  @include ai-center();
}
.df-row-jsb {
  @include flex();
  flex-flow: row nowrap;
  justify-content: space-between;
}
.df-row-jsb-start {
  @include flex();
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.df-row-jsb-center {
  @include flex();
  flex-flow: row nowrap;
  justify-content: space-between;
  @include ai-center();
}
.df-row-jce-center {
  @include flex();
  flex-flow: row nowrap;
  justify-content: flex-end;
  @include ai-center();
}
.df-row-wrap-jce-center {
  @include flex();
  flex-flow: row wrap;
  justify-content: flex-end;
  @include ai-center();
}
.df-row-wrap-jce-end {
  @include flex();
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-end;
}
.df-row-wrap-jce-start {
  @include flex();
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-start;
}
.df-column-jcc-center {
  @include flex();
  flex-flow: column nowrap;
  justify-content: center;
  @include ai-center();
}
.df-column-jce-center {
  @include flex();
  flex-flow: column nowrap;
  justify-content: flex-end;
  @include ai-center();
}
.fg2 {
  flex-grow: 2;
}
.fs0 {
  flex-shrink: 0;
}
.full-width {
  width: 100% !important;
}
.auto-width {
  width: auto !important;
}
.fit-width {
  width: fit-content !important;
}
.mla {
  margin-left: auto !important;
}
.mra {
  margin-right: auto;
}
.mta {
  margin-top: auto;
}
.mba {
  margin-bottom: auto;
}
.maa {
  margin: auto;
}
.ws-br-spaces {
  white-space: break-spaces;
}
.ws-nowrap {
  white-space: nowrap;
}
.cpointer {
  cursor: pointer;
}
.__top-border {
  border-top: 1px dotted $light-gray;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pos-r {
  position: relative;
}
.pos-a {
  position: absolute;
}

.border-link {
  border: 1px solid $link;
  border-radius: 3px;
}

.border-hover {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid $blue-less-opaque;
    border-radius: 3px;
  }
}

button.__rotate-on-hover.btn-custom_main {
  & > svg,
  & > div > svg {
    transition: all 0.5s ease;
  }
  &:hover {
    & > svg,
    & > div > svg {
      transform: rotate(45deg);
      transition: all 0.4s ease;
    }
  }
}

// COLORS
.bg-blue,
.bg-link {
  background-color: $link;
}
.bg-green {
  background-color: $green;
}
.bg-red {
  background-color: $red;
}
.bg-purple {
  background-color: $purple;
}
.bg-orange {
  background-color: $orange;
}
.bg-yellow {
  background-color: $light-yellow !important;
}
.blue,
.link {
  color: $link !important;
}
.green {
  color: $green;
}
.red {
  color: $red;
}
.purple {
  color: $purple;
}
.orange {
  color: $orange;
}
.light-gray {
  color: $light-gray;
}
.mid-gray {
  color: $mid-gray !important;
}
.discrete-text {
  color: $discrete-text;
}
.black {
  color: $black;
}
/* 

Our Standard animations
Use anywhere like this:

some-element {
  animation: jerky-rotation 2s infinite linear;
}

*/

@keyframes jerky-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotate-ccw {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes wobble {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes pump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// scrollbar styling for webkit browsers
.webkit-msedge-chrome-nicer-scrollbar {
  &::-webkit-scrollbar {
    height: 8px;

    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: $light-gray;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 10px;
  }
  &:hover {
    &::-webkit-scrollbar {
    }
  }
}
