@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .firstSetup {
    &__body {
      padding: 0px;
      margin-top: -20px;
      .top-section {
        margin-top: -10px;
      }
      .topic {
        font-size: 16px;
      }
    }
    .first-setup_selection__wrapper {
      padding: 0px;
    }
    &__btn {
      margin: 0px 0px 20px 0px;
    }
    &__text.info-chunk {
      margin-bottom: 20px;
    }
  }
}

@media (min-width: 320px) and (max-width: 991.98px) {
  .firstSetup {
    &__btn {
      margin: 0px 0px 20px 0px;
    }
  }
}
