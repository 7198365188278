.project {
  &__wrapper {
    border-radius: 3px;
    margin-bottom: 40px;
    padding: 0px 0px 40px 40px;
    & > button > span {
      font-size: 14px;
    }
    // TODO REMOVE WITH COMING SOON !!!!
    .df-row-jsb-center {
      position: relative;
    }
  }
  // TODO REMOVE WITH COMING SOON !!!!
  &__coming-soon-icon {
    position: absolute;
    right: -8px;
    top: 30px;
  }

  &__tabs {
    margin-top: 13px;
    display: flex;
    flex-direction: row;
    & button:not(:last-child) {
      margin-right: 20px;
    }
  }

  &-view {
    &-header._wrapper {
      margin-bottom: 20px;

      .back-button {
        padding-top: 0;
      }

      .region-badge {
        background-color: rgba(107, 192, 105, 0.1);
        padding: 6px 20px;
        border-radius: 27px;
        margin-right: 5px;
        color: $green;
        font-size: 12px;
      }

      .name-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
      }

      .responsive-wrap {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .breakdown {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin-bottom: 20px;

          span {
            white-space: nowrap;
          }
        }

        .location-label {
          max-width: 20vw;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &-cost-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background: $white;
      justify-content: flex-end;
      padding: 20px 20px 10px 0;
      & > div {
        font-size: 14px;
      }
    }

    &-procurements-table {
      &_head,
      &_item {
        width: 100%;
        &__name {
          flex-basis: 60%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          a {
            color: $link;
          }
        }
        &__status {
          flex-basis: 20%;
          display: flex;
          justify-content: flex-end;
        }
        &__details {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          min-width: 280px;
          &__title {
            flex-basis: 80%;
            display: flex;
            align-items: center;
          }
          &__icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: 20px;
            min-width: 20px;
          }
          &__messages {
            margin-left: 20px;
            min-width: 60px;
            text-align: right;
          }
        }
        &__pre-cost {
          flex-basis: 1%;
        }
        &__cost {
          min-width: 122px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-weight: 700;
          &.__red {
            color: $red;
          }
        }
      }
      &_head {
        @media screen and (min-width: 1200px) {
          &.scrolled {
            position: fixed;
            top: 0;
            width: calc(100vw - 308px);
            z-index: 5;
            border-radius: 0 0px 3px 3px;
            background: white;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
          }
        }
        padding: 10px 20px 10px 10px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 10px;

        // position: fixed;
        // top: 0;
        // width: calc(100vw - 348px);
        // border-radius: 0 0px 3px 3px;
        // padding: 10px !important;
        // background: white;
        // z-index: 5;
        // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);

        &__name {
          flex-grow: 2;
          & > .multiselect-custom_wrapper.__tiny {
            .multiselect-custom_btn.__wrapper {
              margin-right: 5px;
            }
            .btn-custom_main.btn-transparent.__tiny {
              height: 23px;
              padding-left: 3px;
            }
            .multiselect-custom_select {
              text-transform: initial;
              font-weight: normal;
            }
          }
          .bulk-actions {
            animation: flashy-appear 2s ease-in-out 1;
          }
          @keyframes flashy-appear {
            0%,
            20%,
            40%,
            60%,
            100% {
              opacity: 1;
            }
            10%,
            30%,
            50% {
              opacity: 0.2;
            }
          }
        }
        &__status {
          padding-right: 20px;
        }
        &__details {
          &.disable-response > div:nth-child(1) {
            margin-left: 0px;
          }
          &.disable-response > div:nth-child(3) {
            margin-left: 40px;
          }
        }
        &__dates {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          & > .lock-value {
            height: 100%;
            width: 72px;
            .project-view-procurements-table_head__dates__step {
              min-width: 34px;
            }
            & > svg {
              margin-left: 2px !important;
            }
          }
          &__step {
            height: 100%;
            min-width: 58px;
            margin-left: 20px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            &__head-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
              width: 40px;
              border-radius: 20px;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);

              &.blue {
                background-color: $blue;
              }
              &.green {
                background-color: $green;
              }
              &.black {
                background-color: $black;
              }
              &.white {
                background-color: $white;
              }
            }
          }
        }
        &__cost {
          padding-right: 10px;
        }
      }
      &_item {
        padding: 10px 20px 10px 10px;
        &:nth-of-type(3) {
          padding-top: 20px;
          border-radius: 3px 3px 0 0;
        }
        &:nth-last-of-type(2) {
          padding-bottom: 20px;
          border-radius: 0 0 3px 3px;
        }

        background: $white;
        &:not(:nth-last-of-type(2)) {
          border-bottom: 1px solid #f7f4f4;
        }
        margin-bottom: 0;
        width: 100%;
        &.__dates {
          background: $white;
        }
        &__header {
          display: flex;
          flex-flow: row nowrap;
          width: 100%;
          .project-view-procurements-table_head__dates__step {
            font-weight: 700;
          }
        }
        &__info {
          flex-basis: 80%;
          display: flex;
          flex-flow: column nowrap;
          padding-right: 20px;
          &.__dates {
            flex-basis: 100%;
            flex-grow: 2;
          }
        }
        &__name-status {
          display: flex;
          flex-flow: row nowrap;
        }
        &__name {
          flex-basis: 75%;
          button {
            padding-left: 2px !important;
            svg {
              margin-right: 3px;
              height: 16px;
            }
          }
          &__expand-button {
            display: flex;
            margin-right: 10px;
            cursor: pointer;
            &.__disabled {
              cursor: default;
              & > svg > path {
                fill: $light-gray;
              }
            }
          }
        }
        &__status {
          flex-basis: 25%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }

        &__closed-date {
          margin-right: 20px;
          flex-shrink: 0;
          font-size: 12px;
          & > span {
            margin-left: 5px;
            color: $discrete-text;
          }
        }

        &__cost {
          font-size: 12px;
        }
        &__details {
          flex-flow: column nowrap;
          &__title > span:not(.black) {
            cursor: pointer;
          }
          &__title > svg {
            min-width: 20px;
          }
          &__email-attention {
            width: 250px;
            & > span {
              padding-left: 10px;
              color: $discrete-text;
              font-style: italic;
            }
          }
          &__icon {
            .indicator_sub-status {
              position: relative;
              display: block;
              width: 20px;
              height: 20px;
              margin-left: auto;
              &:before {
                position: absolute;
                content: "";
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                background-repeat: no-repeat;
                background-position: center;
              }
              &.__1 {
                &:before {
                  background-image: url(../../../../images/svg/status/status-check.svg);
                }
              }
              &.__4 {
                &:before {
                  background-image: none;
                }
              }
            }
            & .btn-custom_main.btn-transparent {
              padding: 0px;
              height: 22px;
              & svg {
                margin: 0px;
              }
            }
            &__unread-icon {
              margin-right: -12px;
              margin-left: 3px;
            }
          }
        }
        &__recipient {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          width: 100%;
          width: calc(100% - 30px);
          padding-top: 10px;
          margin-left: 30px;
          &:first-child {
            margin-top: 10px;
          }
          &:last-child {
            margin-bottom: 10px;
          }
          &:not(:last-child) {
            border-bottom: 1px dotted #ededed;
            padding: 10px 0px;
          }
        }
      }
    }
    &_summary-action-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: 5px;
      .projects-select_wrapper > .projects-select_select {
        padding-right: 10px;
        .ant-select-selector {
          padding-right: 15px !important;
        }
        .ant-select-arrow {
          top: 60%;
        }
        .ant-select-selection-item {
          padding-right: 18px !important;
        }
      }
      &__summary {
        margin-left: auto;
        font-size: 12px;
        & > span {
          font-weight: 700;
          text-transform: uppercase;
        }
        &.__red > span {
          color: $red;
        }
      }
      &__post-summary {
        flex-basis: 20%;
      }
    }
  }
  &-edit-block {
    margin-top: 10px;
    width: 72%;
    & > form {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }
    &__row-fields {
      display: flex;
      flex-flow: row wrap;
      margin-bottom: 20px;
      &.re-order-fields {
        & > .location-input {
          order: 2;
        }
        & > .dalux-wrapper {
          order: 3;
        }
      }
      .left-col,
      .right-col {
        width: 50%;
        & > input {
          width: 100%;
          height: 38px;
          padding: 1px 2px 1px 10px;
        }
        .label {
          margin: 0px 0px 10px 0px;
          text-align: left;
        }
        & > label {
          display: block;
          line-height: 130%;
          margin: 0px 0px 10px 0px;
          text-align: left;
        }
        &.form-field_wrapper.location-input .modal-maps__wrapper {
          width: 200% !important;
        }
        &.dalux-wrapper {
          margin-top: 21px;
        }
      }
      .department-select {
        margin: 0px;
        & > .editBlock_field__label {
          padding-left: 0px;
        }
        & > .editBlock_select__field {
          width: 100%;
          & > .ant-select-selector {
            height: 38px;
            padding-left: 10px;
          }
        }
      }
      .left-col {
        padding-right: 20px;
      }
    }
  }
}
