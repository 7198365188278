.modal-ent {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99; /* Stop the "published" modal from interacting with the rich text field behind it */
  overflow: auto;
  &.__local {
    background: none;
  }
  &.__darked {
    background: rgba(0, 0, 0, 0.85);
  }
  &.__place-low {
    z-index: 95;
  }
  &.__place-high {
    z-index: 105;
  }
  &.__transparent-title {
    & > .modal-ent__wrapper {
      background-color: transparent;
      .modal-ent__header {
        background-color: transparent;
        color: $white;
        & > button > svg > path {
          fill: $white;
        }
      }
      .modal-ent__content {
        background: $white;
      }
    }
  }
  &__wrapper {
    width: 60%;
    max-width: 80vw;
    margin: 60px auto;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    background: $white;

    @include for-size(l) {
      width: 75%;
    }
    @include for-size(md) {
      width: 96vw !important;
      overflow-y: scroll;
      max-width: unset;
      max-height: calc(100vh - 4vw);
      position: fixed;
      left: 2vw;
      top: 2vw;
      padding-bottom: 80px;
      margin: 0 auto;
      .form-field_wrapper,
      .label-data__wrapper {
        margin-bottom: 20px !important;
      }
    }

    &.__local {
      width: auto;
      position: absolute;
      margin-bottom: 0px;
    }
  }
  &__title.__local {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
  }
  &__header {
    display: flex;
    justify-content: flex-start;
    background: $off-white;
    color: $black;
    padding: 30px 40px;
    align-items: center;
    font-size: 16px;
    white-space: pre-line;
    @include for-size(md) {
      padding: 30px 20px;
    }
    &__title {
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase;
      margin: 3px 0px 0px 25px;
    }
    &.__bold {
      font-weight: 700;
    }
    &.__rounded {
      border-radius: 3px;
    }
    &.__white-bg {
      background: $white;
    }
    &.__large-title {
      font-size: 20px;
    }
    &.__tight-title {
      padding: 20px;
    }
    &.__huge-title {
      font-size: 24px;
    }
  }
  &__close-modal {
    display: flex;
    margin-left: auto;
    border: none;
    background: transparent;
    cursor: pointer;
    height: 20px;
    align-items: center;
    font-size: 16px;
  }
  &__content {
    text-align: center;
    &.__left-text-content {
      text-align: left;
    }
    @include for-size(md) {
      padding: 0 20px 40px 20px;
    }
    h2 {
      font-weight: 900;
      font-size: 50px;
      line-height: 130%;
      margin: 39px 0 30px 0;
      @include for-size(md) {
        font-size: 23px;
        margin: 20px 0;
      }
    }
    &.__rounded {
      border-radius: 3px;
    }
  }
  &__footer {
    background: $white;
    &.__transparent-footer {
      background-color: transparent;
      color: $white;
    }
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
