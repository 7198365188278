.services-offered {
  &_item {
    width: calc(33% - 19px);
    max-width: 340px;
    margin-bottom: 20px;
    margin-right: 20px;
    border: 1px dotted$mid-gray;
    border-radius: 3px;
    padding: 10px 20px 30px 20px;
    &__inner {
      display: flex;
      align-items: flex-end;
    }
    .services-field_wrap {
      width: 100%;
    }
    .services-action_wrap {
      margin: 0px -12px -23px 15px;
      & > button {
        padding: 0px;
      }
    }
    .field_wrapper {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:last-child {
        p {
          margin-bottom: 0;
        }
      }
      .field {
        width: 100%;
      }
      svg {
        width: 16px;
        margin-right: 10px;
      }
      input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        &:disabled {
          box-shadow: none;
        }
      }
      p {
        margin-bottom: 10px;
      }
    }
  }
}
