.custom-switch {
  &_wrapper {
    display: flex;
    align-items: center;
  }
  &-check {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $green;
      background-color: $green;
    }
    &.ant-switch-checked {
      border-color: $green;
      background-color: $green;
    }
    &:focus {
      box-shadow: none !important;
    }
    &.__red-on {
      &.ant-switch-checked {
        border-color: $red;
        background-color: $red;
      }
    }
  }
  &_label {
    font-size: 12px;
    text-transform: none;
    &.__small {
      font-size: 10px;
    }
    &.__medium {
      font-size: 14px;
    }
    &.__large {
      font-size: 16px;
    }
    &.__grey {
      color: $light-gray;
    }
  }
}
