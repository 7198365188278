.selection {
  &__title {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: $discrete-text;
    &.__small {
      font-size: 12px;
      margin-bottom: 10px;
    }
    &.__tiny {
      font-size: 10px;
      margin-bottom: 10px;
    }
    &.__bold {
      font-weight: 700;
    }
    &.__black {
      color: $black;
    }
  }
  .ant-select {
    background-color: $link;
    &.__red {
      background-color: $red;
      .anticon-down {
        color: $red;
      }
      &.__filled-selected-item .ant-select-selector .ant-select-selection-item {
        background: $red;
      }
      &.__transparent-selected-item .ant-select-selector .ant-select-selection-item {
        background: $white;
      }
      &.__transparent-selected-item {
        border: 1px solid $red;
        .ant-select-arrow > span {
          background: $red;
        }
      }
      .ant-select-selection-placeholder {
        color: $black;
      }
    }
    &.__purple {
      background-color: $purple;
      .anticon-down {
        color: $purple;
      }
      &.__filled-selected-item .ant-select-selector .ant-select-selection-item {
        background: $purple;
      }
      &.__transparent-selected-item {
        border: 1px solid $purple;
        .ant-select-arrow > span {
          background: $purple;
        }
        .ant-select-selection-placeholder {
          color: $black;
        }
      }
    }
    &.__black {
      background-color: $black;
      .anticon-down {
        color: $black;
      }
      &.__filled-selected-item .ant-select-selector .ant-select-selection-item {
        background: $black;
      }
      &.__transparent-selected-item {
        border: 1px solid $black;
        .ant-select-arrow > span {
          background: $black;
        }
      }
    }
    &.__green {
      background-color: $green;
      .anticon-down {
        color: $green;
      }
      &.__filled-selected-item .ant-select-selector .ant-select-selection-item {
        background: $green;
      }
      &.__transparent-selected-item {
        border: 1px solid $green;
        .ant-select-arrow > span {
          background: $green;
        }
      }
    }
    color: $white;
    &.ant-select-open {
      .ant-select-selection-item {
        color: $dimmed-opaque-white;
      }
    }
    &.__transparent-selected-item {
      background: $white;
      border: 1px solid $green;
      color: $black;
      &.ant-select-open .ant-select-selection-item {
        color: $black;
      }
      .ant-select-arrow > span > svg > path {
        fill: $white;
      }
    }

    height: 40px;
    padding: 0;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    font-style: italic;
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      padding-right: 24px;
      padding-left: 10px !important;
      align-items: center;
      @include for-size(md) {
        padding: 0;
      }
    }

    .ant-select-selection-placeholder {
      opacity: 1;
      color: #fff;
    }
    .ant-select-arrow {
      top: 50%;
      right: -30px;
    }
  }
  .anticon-down {
    background: white;
    color: $link;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.select {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  p {
    width: 100%;
  }

  &__title {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 10px;
    margin-bottom: 20px;
    color: $black;
  }

  .ant-select {
    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
      outline: none;
      display: flex;
      flex-flow: wrap;
      padding: 0px 15px;
      & > span {
        padding: 0px 10px 0px 10px;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        cursor: pointer;

        .anticon-close {
          background: white;
          color: $link;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 20px;
            height: 11px;
          }
        }
      }
    }

    &.__bold .ant-select-selector .ant-select-selection-item {
      font-weight: 700;
    }

    &.__small {
      font-size: 12px;
      height: 32px;
      padding: 0px 0px 0px 10px;
      .ant-select-selector {
        padding-right: 16px;
        padding-left: 0px !important;
        .ant-select-selection-item {
          height: 32px;
          font-size: 12px;
        }
      }
      .ant-select-clear {
        width: 20px;
        height: 21px;
        right: 7px;
        margin-top: -11px;
      }
      .ant-select-arrow {
        right: 7px;
        svg {
          margin-top: 2px;
        }
      }
    }
    &.__tiny {
      font-size: 12px;
      height: 26px;
      padding: 0px 10px 0px 10px;
      .ant-select-selector {
        padding-right: 16px;
        padding-left: 0px !important;
        .ant-select-selection-item {
          height: 26px;
          font-size: 12px;
        }
      }
      .ant-select-arrow {
        right: 7px;
        svg {
          margin-top: 2px;
        }
      }
    }
    &.__large .ant-select-selector {
      height: 36px;
      .ant-select-selection-search {
        line-height: 36px;
      }
    }
    &.__huge .ant-select-selector {
      height: 40px;
      border-radius: 3px;
      .ant-select-selection-search {
        line-height: 40px;
      }
    }
    &.__round .ant-select-selector {
      border-radius: 16px;
    }
    &.__round.__large .ant-select-selector {
      border-radius: 18px;
    }
    &.__round.__huge .ant-select-selector {
      border-radius: 20px;
    }
    &.__shadow .ant-select-selector {
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25) !important;
      border: 1px solid transparent;
    }
    &.__small-font .ant-select-selector {
      font-size: 12px;
    }
    &.__tiny-font .ant-select-selector {
      font-size: 10px;
    }
    &.__small-font.__large .ant-select-selector {
      .ant-select-selection-placeholder,
      .ant-select-selection-search {
        line-height: 36px;
      }
    }
    &.__show-arrow .ant-select-selector > span {
      padding: 0px 30px 0px 10px;
    }
    &.__min-width .ant-select-selector span {
      padding: 0px;
    }
    &.__min-width.__show-arrow .ant-select-selector span {
      padding: 0px 15px 0px 0px;
    }
    &.__filled-selected-item .ant-select-selector .ant-select-selection-item {
      border-radius: 20px;
      background: $link;
      color: $white;
    }
    &.__full-width {
      width: 100%;
    }
    &.__prefix-icon .ant-select-selection-placeholder {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding-left: 0px !important;
      svg {
        margin-right: 15px;
        height: 45%;
      }
    }

    &.bg-yellow > .ant-select-selector {
      background-color: $light-yellow;
    }

    &.render-dropdown-into-select.ant-select-single {
      max-height: 0px !important;
      max-width: 0px !important;
      & > .ant-select-selector {
        max-height: 0px;
        padding: 0px;
        & > .ant-select-selection-search {
          padding: 0px;
          & > input {
            padding: 0px;
          }
        }
      }
    }
  }

  &__add-btn {
    color: $link;
    padding: 9px;
    background: $white;
    border: 1px solid $link;
    border-radius: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;

    &:before {
      content: url("../../../images/svg/plus.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
      margin-right: 10px;
    }
  }
}

.ant-select-dropdown {
  max-width: 320px !important;
  min-width: 210px !important;
  .ant-select-item-option-content {
    white-space: pre-wrap !important;
  }
  &.align-rigth-row .ant-select-item-option-content {
    text-align: right;
    display: inline-block;
  }
  &.no-max-height {
    .rc-virtual-list-holder {
      max-height: unset !important;
    }
    .rc-virtual-list-scrollbar-thumb {
      display: none;
    }
  }
}

.selections-group {
  &_wrapper {
    margin-bottom: 40px;

    &.__open {
      margin-bottom: 10px;
      .multiselect-custom_wrapper {
        margin-bottom: 30px;
      }
    }
    &:not(.__open) {
      margin-bottom: 20px;
    }
  }
  &_inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  &_switch {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &__label {
      margin-left: 20px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.3);
      transition: all 0.2s ease-in-out;
      &._checked {
        color: $black;
      }
      &._disabled {
        cursor: not-allowed;
      }
    }
  }
  &_selects {
    margin-right: 50px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &_sub-title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    line-height: 1;
  }
}

.more-setup-selections {
  &_wrapper {
    padding: 20px 20px 1px 20px;
    background: #fafafa;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    &._edit {
      background-color: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    }
  }
  &_sub-title {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 3px;
    padding: 5px 5px 4px 5px;
  }
}

.projects-select {
  &_wrapper {
    border-radius: 3px;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    height: 60px;
    display: flex;
    align-items: center;
    padding-right: 20px;
    &.no-border {
      border: none;
      box-shadow: none;
      height: 40px;
      .ant-select-selector > span.ant-select-selection-item {
        padding-top: 0px;
      }
      span.ant-select-arrow {
        margin-top: -12px;
        right: -15px;
      }
    }
    &.__full-width {
      width: 100%;
      padding: 0px;
      .projects-select_select.ant-select {
        width: 100%;
        .ant-select-selector {
          padding: 0px;
          width: 100%;
          .ant-select-selection-item {
            width: 100%;
            max-width: 100%;
            padding-right: 45px;
          }
        }
      }
    }
    &.__large {
      .projects-select_select > .ant-select-selector {
        height: 34px;
      }
      .projects-select_select > span.ant-select-arrow {
        margin: -9px 10px 0px 0px;
      }
    }

    button.btn-transparent {
      padding: 0px;
      svg {
        margin: 0px;
      }
    }
  }
  &_select {
    height: 40px;
    .ant-select-selector span.ant-select-selection-item {
      padding-top: 8px;
      flex: none;
      max-width: 200px;
    }
    .project-label_wrapper {
      line-height: 14px;
    }
    span.ant-select-arrow svg {
      margin: -5px 20px 0px 0px;
    }
  }
}

.projects-select_dropdown {
  .rc-virtual-list {
    .ant-select-item-option-content {
      flex: none;
    }
    .project-label_wrapper {
      flex: none;
      line-height: 14px;
    }
  }
}

.procurement-statuses-select {
  &_wrapper {
    .ant-select-selector {
      border: none !important;
      padding: 0px !important;
      height: auto !important;
      cursor: pointer !important;
      .ant-select-selection-item,
      .ant-select-selection-overflow-item,
      .ant-select-selection-placeholder {
        padding: 0px !important;
        display: flex;
        align-items: center;
        .custom-tag {
          margin: 0px;
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
          border-radius: 3px;
        }
      }
      .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix,
      .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
        display: none;
      }
      .ant-select-selection-placeholder {
        display: contents;
        & > .custom-tag {
          margin-top: 1.5px;
        }
      }
    }
  }
}

.procurement-statuses_dropdown {
  min-width: min-content !important;
  &.ant-select-dropdown {
    padding: 0;
  }
  .ant-select-item.ant-select-item-option {
    padding-top: 3px;
    padding-bottom: 3px;
    min-height: 20px;
    &:first-child {
      padding-top: 10px;
    }
    &:last-child {
      padding-bottom: 10px;
    }

    & > .ant-select-item-option-state {
      display: none;
    }
    .custom-tag {
      margin: 0px;
      width: 100%;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      border-radius: 3px;
      & > svg {
        display: none;
      }
    }
  }
}

.mini-select {
  &_wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  &_select {
    .ant-select-selector {
      padding: 0px !important;
      height: auto !important;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        padding: 0px !important;
        line-height: 26px !important;
        .custom-tag {
          margin: 0px;
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
          border-radius: 12px !important;
          height: 23px !important;
          & > svg {
            margin: 0px 0px -3px 5px;
          }
        }
      }
    }
  }
  &_label {
    &.__tiny {
      font-size: 10px;
    }
  }
}

.mini-select_dropdown {
  min-width: min-content !important;
  .ant-select-item.ant-select-item-option {
    padding-top: 3px;
    padding-bottom: 3px;
    min-height: 20px;
    & > .ant-select-item-option-content {
      display: flex;
      align-items: center;
      & > svg {
        margin-right: 10px;
      }
    }
    .custom-tag {
      margin: 0px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      border-radius: 12px !important;
      height: 23px !important;
      text-align: center;
      & > svg {
        display: none;
      }
    }
  }
  &.full-width-labels
    .ant-select-item.ant-select-item-option
    > .ant-select-item-option-content {
    flex: auto;
  }
  &.organization-page__contact-persons-dropdown {
    min-width: 170px !important;
  }
}

.question-select-dropdown {
  .ant-select-item-option-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
    display: unset !important;
  }
}

.editBlock_select_dropdown {
  &.initials {
    .rc-virtual-list {
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        padding: 1px;
      }
    }
  }
}

.multi-select {
  &__wrapper {
    &.__full-width {
      .ant-select {
        width: 100%;
      }
    }
    &.__big-select {
      .ant-select-selector {
        height: 60px !important;
        padding: 1px 0px 1px 20px !important;
        border: none !important;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      }
      .ant-select-arrow {
        display: none;
      }
      .ant-select-selection-search {
        right: 20px !important;
        padding-left: 10px;
        &:after {
          position: absolute;
          content: "";
          cursor: pointer;
          display: block;
          top: calc(50% - 2px);
          right: 3px;
          width: 10px;
          height: 10px;
          transform: translateY(-50%) rotate(-45deg);
          border-bottom: 1px solid $black;
          border-left: 1px solid $black;
          transition: 0.2s;
          visibility: inherit;
        }
        .ant-select-selection-search-input {
          height: 100% !important;
        }
      }
      .ant-select-selection-item {
        cursor: pointer;
        line-height: 58px !important;
      }
    }
  }
}

.button-select {
  display: flex;
  &.__select {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
  &-dropdown {
    .ant-select-item.ant-select-item-option {
      padding: 3px 10px;
    }
    .custom-tag {
      width: 100%;
      padding: 3px 10px;
    }
    &.__turnover {
      min-width: 137px !important;
    }
    &.__credit-rating {
      min-width: 120px !important;
    }
  }
  .btn-custom_main.btn-transparent {
    justify-content: center;
    &.__small {
      width: 32px;
    }
    & > span {
      height: unset !important;
    }
    span {
      font-size: 14px;
    }
  }
}

.financial-snapshot-select {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  button.btn-transparent {
    height: 26px;
    padding: 5px 10px;
  }
  div > div.select {
    max-height: 0px;
    max-width: 0px;
  }
  &-dropdown {
    min-width: 0px !important;
    top: 25px !important;
    left: -132px !important;
    width: fit-content !important;
    button.btn-transparent {
      height: 26px;
      padding: 5px 10px;
      width: calc(100% - 24px);
      margin: 5px 12px;
      & > svg {
        flex-shrink: 0;
      }
    }
    .ant-select-item-option.ant-select-item-option-active {
      background-color: transparent;
    }
    &.__horizontal-view > div {
      display: flex;
    }
  }
  &-dropdown-download-button {
    display: flex;
    align-items: center;
    height: 26px;
    margin: 0;
    padding: 0px 5px;
    width: 100%;
    background: transparent;
    border-radius: 3px;
    border-color: rgba(27, 162, 204, 0.1) !important;
    font-size: 12px;
    & > span {
      text-align: center;
      width: 100%;
    }
    &:hover {
      border-color: rgba(27, 162, 204, 0.3) !important;
    }
    border: none;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    color: $link;
    cursor: pointer;
    svg {
      margin-right: 5px;
      min-width: 20px;
    }
  }
}
