.editBlock {
  &_wrapper {
    margin-bottom: 70px;
    padding: 25px 20px;
    border-radius: 3px;
    border: 1px dotted $mid-gray;
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    &__actions {
      display: flex;

      &._center {
        justify-content: center;
      }
    }

    &__title {
      font-size: 16px;
      color: $black;
      font-weight: 700;
      text-transform: uppercase;
      label {
        font-weight: 400;
      }
    }

    &__btn {
      margin: 0 5px;
      cursor: pointer;
    }
  }

  &_body {
    .btn-custom_wrap {
      margin-top: 50px;
    }

    &.__flex {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &_flex__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &_item {
    position: relative;
    margin-bottom: 25px;
    padding-bottom: 25px;

    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 70%;
      border-bottom: 1px dotted $light-gray;
    }
  }

  &_field,
  &_select,
  &_date-picker,
  &_radio {
    &__item {
      position: relative;
      margin-right: 20px;
    }

    &__label {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      padding-left: 20px;
      min-width: 180px;
    }
  }

  &_field {
    &__item {
      .input {
        font-weight: 700;
      }
    }
  }

  &_select {
    &__field.ant-select {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          display: flex;
          align-items: center;
          min-width: 180px;
          height: 60px;
          background: $white;
          border: none;
          font-size: 14px;
          line-height: 17px;
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
          border: 0;
          border-radius: 3px;
          outline: none;
          padding-left: 20px;

          &:after {
            display: none;
          }

          .ant-select-selection-item {
            position: relative;
            padding-right: 30px;
          }
        }
      }

      &.show-placeholder-arrow > .ant-select-selector > .ant-select-selection-placeholder,
      & > .ant-select-selector > .ant-select-selection-item {
        &:after {
          position: absolute;
          content: "";
          display: block;
          top: calc(50% - 2px);
          right: 3px;
          width: 10px;
          height: 10px;
          transform: translateY(-50%) rotate(-45deg);
          border-bottom: 1px solid $black;
          border-left: 1px solid $black;
          transition: 0.2s;
          visibility: inherit;
        }
      }
      &.show-placeholder-arrow
        > .ant-select-selector
        > .ant-select-selection-placeholder::after {
        margin-right: 11px;
      }

      &.ant-select-open,
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25) !important;
        }
      }

      .ant-select-arrow {
        display: none;
      }

      &.__placeholder-prefix-icon .ant-select-selection-placeholder > svg {
        vertical-align: middle;
        margin: 0px 10px 3px 0px;
      }

      &.__placeholder-color-blue .ant-select-selection-placeholder {
        color: $blue;
      }

      &.bg-yellow > .ant-select-selector {
        background-color: $light-yellow;
      }
    }

    &__field.__small > .ant-select-selector {
      height: 40px !important;
    }

    &__field.ant-select.initials .ant-select-selection-item {
      padding-top: 1px;
      display: flex;
      align-items: center;
    }
  }

  &_date-picker {
    &__field.ant-picker {
      display: flex;
      min-width: 180px;
      height: 60px;
      background: $white;
      border: none;
      font-size: 14px;
      line-height: 17px;
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
      border: 0;
      border-radius: 3px;
      outline: none;
      padding-left: 20px;

      &:focus,
      &:hover {
        outline: none;
        border: 0;
      }

      &.__small {
        height: 40px;
      }

      .ant-picker-input {
        input {
          &::-webkit-input-placeholder {
            color: $discrete-text;
          }

          &::-moz-placeholder {
            color: $discrete-text;
          }

          &:-ms-input-placeholder {
            color: $discrete-text;
          }

          &:-moz-placeholder {
            color: $discrete-text;
          }
        }
      }
    }
  }

  &_radio {
    &__wrapper {
      display: flex;
      align-items: center;
      padding-top: 25px;
    }

    &__title {
      margin-right: 20px;
      padding-left: 20px;
      font-size: 14px;
    }

    &__item {
      display: flex;
      align-items: center;
      padding-top: 0;

      .ant-switch {
        margin-right: 5px;

        &.ant-switch-checked {
          background-color: $green;
        }
      }

      label {
        font-size: 14px;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }

  &_upload {
    &__item {
      display: flex;
      align-items: center;
      padding-top: 20px;
      margin-right: 20px;
      &.__row-view {
        margin-bottom: -10px;
      }
    }

    &__links {
      display: flex;
      flex-flow: column nowrap;
      &.__row-view {
        flex-flow: row wrap;
      }
      & > div {
        display: flex;
        flex-flow: row nowrap;
      }
    }

    &__link {
      color: $link !important;
      text-decoration: none;
      padding: 5px;
      border: 1px solid rgba(27, 162, 204, 0.1);
      border-radius: 3px;
      margin-right: 10px;

      &.__mini-load-files {
        font-size: 12px;
        & > svg {
          width: 16px;
          height: 16px;
        }
      }

      & > svg {
        margin: 0px 5px -4px 0px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &__btn {
      font-size: 13px;
      height: auto;
    }
  }

  &_btn__clean {
    margin-top: 23px;
  }

  &_header {
    &__input-site {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        color: $black;
        fill: $black;
        margin-right: 10px;
      }
    }
  }
}
