.public-rfps {
  background: $white;
  @include for-size(xs) {
    background: none;
  }
  position: relative;
  border-radius: 3px;
  &-table-title {
    margin-top: 50px;
  }
  .empty-data {
    text-align: center;
    font-weight: 700;
  }
  &.content-block_wrapper {
    min-height: 322px;
    @include for-size(xs) {
      padding: 10px 0 0 0;
    }
    & > .subscription-upgrade-modal-local__wrapper {
      top: 80px;
      right: 23%;

      @include for-size(xs) {
        position: fixed !important;
        padding: 20px 40px;
        bottom: 160px;
        z-index: 999;
        top: unset;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
        right: 2% !important;
        width: 80vw !important;
        height: fit-content;
        span {
          font-size: 16px !important;
        }
      }
    }
  }
  .content-block {
    &_header {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 0px;
      padding: 40px 40px 0 40px;
      @include for-size(xs) {
        padding: 0;
      }
      .heading {
        display: flex;
        align-items: center;
        padding: 0;
        & > svg {
          margin: 2px 20px 0px 0px;
          width: 40px;
          height: 36px;
        }
        .live {
          color: $red;
          font-weight: bold;
          font-size: 16px;
          text-transform: uppercase;
          margin-right: 10px;
          line-height: 1;
        }

        @include for-size(xs) {
          padding: 20px 10px;
        }
      }
      .notifyer {
        position: relative; //so we can position the handypointerblob inside it
        h2 {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 12px;
          margin: 1px 10px 0 0;
        }
        @include for-size(sm) {
          width: 100%;
          margin-left: 2px;
          display: flex;
          justify-content: flex-start;
          .handy-pointer-blob {
            display: none;
            visibility: hidden;
          }
        }
      }
      .share-feature {
        @media (max-width: 1220px) {
          display: none;
          visibility: hidden;
        }
      }
    }
    &_wrapper {
      min-height: 50vh;
      .not-bold h3 {
        font-weight: 400;
      }
    }
    &_title {
      line-height: 31px;
      font-weight: 800;
      @include for-size(xs) {
        font-size: 4.5vw;
      }
    }
  }

  &__filters {
    & svg {
      flex-shrink: 0;
    }

    .multiselect-custom_list.__wrapper {
      width: unset;
    }
    .multiselect-custom_wrapper {
      margin-right: 0px !important;
      & .multiselect-custom_btn.__wrapper {
        margin-right: 10px;
      }
    }

    @media (min-width: 320px) and (max-width: 991.98px) {
      padding: 30px 10px 20px 10px !important;
    }
  }
  &__filter-settings-modal {
    .multiselect-custom_wrapper {
      margin-right: 0px !important;
      & .multiselect-custom_btn.__wrapper {
        margin-right: 10px;
      }
    }
  }

  .requests-card {
    &_head {
      padding: 10px;
      margin: 0;
    }
    &_body {
      padding: 10px;
      margin: 0;
    }

    &_head,
    &_body {
      &__column {
        &.__category {
          width: 14%;
          padding-right: 20px;
        }
        &.__region {
          width: 10%;
          padding-right: 20px;
        }
        &.__published-at {
          width: 10%;
          padding-right: 20px;
        }
        &.__time-left {
          width: 15%;
          padding-right: 20px;
        }
        &.__customers {
          width: 14%;
          padding-right: 20px;
        }
        &.__project-name {
          width: 17%;
          padding-right: 20px;
        }
        &.__rfp-name {
          width: 20%;
        }
        &.__category,
        &.__region {
          display: flex;
        }
      }
    }
    &_head {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
    &_body {
      &:hover {
        border-color: $link !important;
        color: $link !important;
        & > .requests-card_body__column.__wrapper {
          & .custom-tag.__medium-select-view {
            border-color: $link !important;
            color: $link !important;
          }
          & > svg > g > path {
            fill: $link;
          }
          &.__turnover {
            color: $link;
          }
        }
      }

      &__column {
        &.__wrapper {
          font-size: 14px;
          line-height: 25px;
          align-items: flex-start;
        }
        &.__project-name,
        &.__customers,
        &.__rfp-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.__category .ant-tag.custom-tag,
        &.__region .ant-tag.custom-tag {
          display: block;
          width: fit-content;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.__turnover {
          display: flex;
          align-items: center;
          color: $green;
          &.__red {
            color: $red;
            & > svg > g > path {
              fill: $red;
            }
          }
          & > svg > g > path {
            fill: $green;
          }
        }
      }
    }
  }

  .custom-upgrade-box {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    padding: 20px;

    @media only screen and (min-width: 1401px) {
      position: absolute;
      top: 133px;
      height: 184px;
      align-items: center;
      left: 37%;
      margin-right: 40px;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1400px) {
      position: absolute;
      top: 133px;
      height: 184px;
      align-items: center;
      left: 41%;
      margin-right: 40px;
    }
    @media only screen and (min-width: 1001px) and (max-width: 1200px) {
      position: absolute;
      top: 133px;
      left: 59%;
      margin-right: 40px;
    }

    @media only screen and (max-width: 1000px) {
      position: relative;
      top: auto;
      margin: 0 10px 20px 10px;
    }
    .offer {
      width: min-content;
      p {
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        padding: 0;
      }

      .cta {
        line-height: 1;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        .action {
          display: flex;
          align-items: center;
          padding: 20px 20px 20px 20px;
          border-radius: 3px;
          background: $purple;
          &:hover {
            background: $blue;
          }
          color: $white;
          text-decoration: none;
          font-size: 12px;
          white-space: nowrap;
          .pulser {
            display: block;
            z-index: 0;
            width: 20px;
            height: 20px;
            background: $blue;
            border: 1px solid #ffffff6e;
            position: absolute;
            border-radius: 50%;
            animation: pulse 2s ease 0s infinite normal none;
          }
          .icon {
            z-index: 2;
          }
          .text {
            display: flex;
            flex-direction: column;
            span {
              display: flex;
              &:first-child {
                margin-bottom: 5px;
              }
            }
            .name {
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .price-blob {
      display: flex;
      position: absolute;
      top: -20px;
      right: -100px;
      @media only screen and (min-width: 1001px) and (max-width: 1200px) {
        top: -100px;
        right: 0;
      }
      @media only screen and (max-width: 1000px) {
        top: 23px;
        right: 23px;
      }
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 120px;
      .star {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .star-content {
        position: relative;
        color: $black;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.1;
        padding: 0 10px;
        .price {
          color: $red;
          font-size: 30px;
        }
        .price-type {
          text-align: center;
          font-size: 12px;
          position: relative;
        }
      }
    }
  }

  .custom-offer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 134px;
    right: 40px;
    z-index: 98;
    width: 130px;
    height: 130px;
    @media only screen and (max-width: 800px) {
      display: none;
      visibility: hidden;
    }

    .star {
      animation: rotate 60s infinite linear;
      position: absolute;
      top: 0;
      right: 0;
    }
    .star2 {
      animation: rotate-ccw 60s infinite linear;
      position: absolute;
      top: 0;
      right: 0;
    }

    .star-content {
      position: relative;
      color: $black;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1;
      .head {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 2px;
      }
      .price {
        color: $red;
        font-size: 32px;
      }
    }
  }

  .custom-campaign {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: $black; // this is a fallback option. the campaign is normally styled inline.
    color: $white; // fallback option
    padding: 10px 20px;
    .info {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
