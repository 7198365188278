.info-strip {
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    color: $white;
    font-size: 16px;
    @include for-size(xs) {
      & > span {
        padding-left: 15px;
      }
    }

    .btn-custom__link {
      &:hover {
        color: $white;
        border-bottom: 1px solid $white;
      }
      @include for-size(xs) {
        margin-left: 0px;
        margin-right: 15px;
      }
    }
  }
}

.divider {
  border-bottom: 1px dashed $light-gray;
  width: 100%;
  &.dotted {
    border-bottom: 1px dotted $light-gray;
  }
}
