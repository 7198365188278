.ant-checkbox-wrapper.checkbox {
  & > .ant-checkbox {
    &::after {
      border-color: $black;
    }
    & > .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      background-color: transparent;
      border-color: $black;
      &::after {
        width: 5.14px;
        height: 10.15px;
        border-color: $black;
        border-width: 1px;
        top: 45%;
        left: 25%;
      }
    }
    &.ant-checkbox-checked {
      & > .ant-checkbox-inner::after {
        width: 5.14px;
        height: 10.15px;
        border-color: $black;
        border-width: 1px;
        top: 45%;
        left: 25%;
      }
    }
    &.ant-checkbox-indeterminate {
      & > .ant-checkbox-inner::after {
        width: 12px;
        height: 1px;
        left: 50%;
        top: 50%;
        background-color: $black;
      }
    }
  }
}
