.modal-published {
  padding: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &__info {
    align-self: center;
    width: 100%;
    max-width: 345px;
    text-align: left;
  }
  &__description,
  &__action {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 16px;
    white-space: nowrap;
  }
  &__description {
    margin-bottom: 40px;
  }
  &__action {
    margin: 0px 0px 10px 0px;
    white-space: nowrap;
  }
  &__notification {
    font-size: 12px;
    margin: 40px 0px;
  }
  .btn-custom_main {
    white-space: nowrap;
  }
}
