.requests-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid transparent;
  align-items: center;
  &:hover {
    border: 1px solid $link;
    border-radius: 3px;
  }
  &_body {
    cursor: pointer;
  }
  &__left-side {
    display: flex;
    align-items: center;
  }
  &__right-side {
    font-weight: bold;
    font-size: 9px;
    i {
      color: gray;
    }
    img {
      margin-left: 40px;
    }
  }
  &__remove-icon {
    background: transparent;
    border: none;
    margin-left: 40px;
  }
  &__logo {
    margin-right: 20px;
    padding: 16px 10px;
    font-size: 14px;
    font-style: italic;
    border: 1px solid $light-gray;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    width: 90px;
    overflow: hidden;
    text-align: center;
  }
  &__about {
    display: flex;
    align-items: center;
    h2 {
      font-size: 16px;
      color: $link;
      margin-bottom: 0;
      margin-right: 10px;
      white-space: nowrap;
    }
    div {
      display: flex;
    }
    .btn-custom__delete {
      cursor: pointer;
    }
  }
  &__favorite {
    display: flex;
    align-items: center;
    button {
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  &__btns {
    flex-wrap: wrap;
    width: 100%;
    button {
      font-size: 12px;
      padding: 5px 10px;
      height: 22px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

// HEAD AND BODY
.requests-card {
  &_head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px 10px 0px 10px;
    margin-bottom: 10px;
    margin-left: -10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    &__name {
      &.__wrapper {
        width: 25%;
        margin-right: 40px;
      }
      &.__select {
        .ant-select {
          min-width: 55px;
          .ant-select-selector {
            background-color: transparent;
            border: 0;
            font-size: 12px;
            padding-left: 2px;
            &:after {
              display: none;
            }
            &:hover {
              background-color: #efefef;
            }
            .ant-select-selection-item {
              position: relative;
              padding-right: 30px;
              font-weight: 700;
              &:after {
                position: absolute;
                content: "";
                display: block;
                top: calc(50% + 3px);
                right: 3px;
                width: 10px;
                height: 10px;
                transform: translateY(-50%);
                border: 6px solid transparent;
                border-top: 6px solid $link;
                transition: 0.2s;
                visibility: inherit;
              }
            }
          }
          &.ant-select-open,
          &.ant-select-focused {
            .ant-select-selector {
              box-shadow: none !important;
            }
          }
          .ant-select-arrow {
            display: none;
          }
        }
      }
    }
    &__indicator {
      &.__wrapper {
        display: flex;
        align-items: center;
      }
    }
    &__number {
      &.__wrapper {
        width: 12%;
      }
    }
    &__column {
      &.__wrapper {
        width: 17%;
      }
    }
    .filler {
      width: 24%;
    }
  }
  &_body {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin-left: -10px;
    border: 1px solid transparent;
    border-radius: 3px;
    transition: 0.3s;
    color: $black;
    &:hover {
      border-color: rgba($link, 0.3);
      color: $link;
      .icon-table,
      .icon-table svg {
        fill: $link !important;
      }
      .badge sup {
        background-color: $link;
      }
    }

    &.nohover {
      border: 1px solid transparent;
      color: $black;
      cursor: default;
    }

    &__column {
      &.__wrapper {
        width: 17%;
        font-size: 14px;
        line-height: 22px;
      }
      &.title {
        font-size: 14px;
      }
      &.date {
        white-space: nowrap;
        padding-top: 1px;
        .separator {
          margin: 0 5px;
          opacity: 0.2;
        }
      }
    }

    &__number {
      &.__wrapper {
        width: 12%;
        font-size: 16px;
      }
    }

    &__name {
      &.__wrapper {
        align-items: center;
        width: 25%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 40px;
        font-size: 16px;
      }
    }

    &__indicator {
      &.__wrapper {
        width: 24%;
        display: flex;
        justify-content: flex-end;
      }
      .table-badge {
        margin: 0px 10px 0px 10px !important;
      }
      .icon-table {
        margin-top: 2px;
      }
    }

    &__icon {
      &.__wrapper {
        flex-shrink: 0;
        width: 32px;
        display: flex;
        align-items: center;
        img {
          margin-top: -5px;
        }
      }
    }

    &__recipient-name {
      &.__wrapper {
        display: flex;
        align-items: center;
        width: 39%;
        &.__unregistered {
          width: 37%;
        }
      }
    }
    &__recipient-reminders-column {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 44%;
    }
    &__recipient-invite-button {
      width: 40%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .organization-card_body__indicator {
      &.__sub-status {
        width: 5%;
        margin: 0px;
      }
    }

    .initials-badge {
      color: $black;
    }
  }
}

.recipient-invite-button-tooltip {
  .ant-tooltip-inner {
    border-radius: 3px;
    white-space: pre-wrap;
    width: fit-content;
  }
}

.organization-card_body__indicator__user-tooltip {
  white-space: pre-line;
}
