.subscription-upgrade-modal {
  &-local__wrapper {
    position: absolute !important;
    padding: 20px;
    width: 234px !important;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__wrapper {
    .modal-ent__header {
      text-transform: none;
      & > svg {
        flex-shrink: 0;
      }
    }
  }
  &__locked {
    text-align: center;
    font-weight: 400;
    text-transform: none;
  }
}
