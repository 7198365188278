@mixin for-size($size) {
  @if $size == xs {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == sm {
    @media (max-width: 700px) {
      @content;
    }
  } @else if $size == md {
    @media (max-width: 900px) {
      @content;
    }
  } @else if $size == ml {
    @media (max-width: 1000px) {
      @content;
    }
  } @else if $size == l {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $size == xl {
    @media (max-width: 1400px) {
      @content;
    }
  } @else if $size == xxl {
    @media (max-width: 1700px) {
      @content;
    }
  }
}

// MARGIN

@for $i from -40 through 40 {
  .mt-#{$i} {
    margin-top: calc(5px * #{$i}) !important;
  }

  .mr-#{$i} {
    margin-right: calc(5px * #{$i}) !important;
  }

  .mb-#{$i} {
    margin-bottom: calc(5px * #{$i}) !important;
  }

  .ml-#{$i} {
    margin-left: calc(5px * #{$i}) !important;
  }

  .ma-#{$i} {
    margin: calc(5px * #{$i}) !important;
  }

  .mrl-#{$i} {
    margin: auto calc(5px * #{$i}) !important;
  }

  .mtb-#{$i} {
    margin: calc(5px * #{$i}) auto !important;
  }
}

// PADDING

@for $i from 0 through 40 {
  .pt-#{$i} {
    padding-top: calc(5px * #{$i}) !important;
  }

  .pr-#{$i} {
    padding-right: calc(5px * #{$i}) !important;
  }

  .pb-#{$i} {
    padding-bottom: calc(5px * #{$i}) !important;
  }

  .pl-#{$i} {
    padding-left: calc(5px * #{$i}) !important;
  }

  .pa-#{$i} {
    padding: calc(5px * #{$i}) !important;
  }

  .prl-#{$i} {
    padding: 0 calc(5px * #{$i}) !important;
  }

  .ptb-#{$i} {
    padding: calc(5px * #{$i}) 0 !important;
  }
}

// FONT SIZE

@for $i from 6 through 40 {
  .fz-#{$i} {
    font-size: #{$i}px !important;
  }
}

// LINE HEIGHT

@for $i from 6 through 32 {
  .lh-#{$i} {
    line-height: #{$i}px !important;
  }
}

// Z-INDEX

@for $i from 1 through 100 {
  .zi-#{$i} {
    z-index: #{$i};
  }
}

@for $i from 1 through 500 {
  .wdth-#{$i} {
    width: #{$i}px !important;
  }
}

@for $i from 1 through 10 {
  .wdthp-#{$i} {
    width: calc(#{$i} * 10%) !important;
  }
}

@for $i from 1 through 500 {
  .hght-#{$i} {
    height: #{$i}px !important;
  }
}

@for $i from 1 through 50 {
  .top-#{$i} {
    top: calc(5px * #{$i}) !important;
  }
}

// FLEX

@mixin flex() {
  display: flex !important;
}
@mixin ai-center() {
  align-items: center !important;
}
