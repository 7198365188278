.modal-email-identify{
  padding: 80px 0px;
  width: 50%;
  margin: auto;
  h2 {
    margin: 0px;
    margin-bottom: 40px;
  }
  &__text {
    font-size: 16px;
  }
  &__text-next-code {
    margin-top: 10px;
    color: $red;
    font-weight: 700;
  }
  .transparent-button {
    background-color: transparent;
    color: $link;
    border: 1px solid $link;
    border-radius: 3px;
  }
  &__input-email {
    margin-top: 40px;
    & > input {
      width: 80%;
    }
    .form-error {
      margin-left: 60px;
    }
  }
}