.monitor-suppliers-filters-modal {
  &__wrapper > .modal-ent__content {
    padding: 20px;
  }
  &__filters {
    padding: 10px 0px;
    max-height: 352px;
    overflow-y: auto;
    border-radius: 3px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12) inset;
    & > .custom-switch_wrapper {
      margin: 0px 2px;
      padding: 7px 18px;
      &:nth-child(2n) {
        background-color: $off-white;
      }
    }
  }
}
