.decorative-search {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  h3 {
    font-size: 24px;
    text-transform: uppercase;
    margin-left: 22px;
    margin-bottom: 0;
    line-height: 130%;
    @include for-size(md) {
      display: none;
    }
  }
  &__buttons {
    display: flex;
    align-items: flex-end;
    flex: 1 0 0;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}
