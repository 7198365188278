.our-blacklist {
  .content-block_header {
    svg {
      width: 38px;
      height: 38px;
    }
    @include for-size(md) {
      margin-bottom: 40px;
    }
  }
  .info-chunk {
    margin-top: 40px;
    margin-left: 10px;
    @include for-size(md) {
      margin-top: 20px;
      margin-left: 0px;
      line-height: 1.25em;
    }
  }
  .organization-card_body__name.__action {
    @include for-size(md) {
      margin-left: auto;
    }
  }
  &__organizations-table {
    position: relative;
    .subscription-upgrade-modal {
      &__wrapper {
        top: 40px;
        right: 10px;
      }
    }
  }
}
