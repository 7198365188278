.stripe-form {
  width: 100%;
  input {
    width: 100%;
    margin-bottom: 5px;
  }

  span {
    display: block;
    margin-bottom: 80px;
  }

  &__btn {
    width: 225px;
    margin: 10px auto 40px auto;
    display: block;
    @include for-size(md) {
      width: 100%;
      margin-bottom: 60px;
    }
  }

  .card-element {
    height: 60px;
    background: $white;
    border: none;
    font-size: 20px;
    line-height: 17px;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    outline: none;
    padding-left: 20px;
    padding-top: 17px;
  }
}
