.invite-user {
  &__wrapper {
    padding: 0 40px 40px 40px;
    @include for-size(sm) {
      padding: 0 20px 40px 20px;
    }
    max-width: 1000px;
    @include for-size(sm) {
      max-width: 100vw;
    }
  }
  &__title {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: 700;
  }
  &_form {
    &__wrapper {
      margin-bottom: 50px;
      .btn-custom_wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .btn-custom_main {
          margin-right: 0;
        }
      }
    }
    &__item {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 15px;
      .input {
        min-width: 200px;
        background-color: #fff;
      }
      p {
        margin-bottom: 0;
      }
      & > .btn-custom__delete {
        position: absolute;
        top: 20px;
        right: 0;
      }
    }
  }
  &__modal-confirm {
    &__description {
      font-size: 16px;
    }
  }
  &__table {
    &.__wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      &.__preloader {
        .preloader500 {
          height: auto;
        }
      }
    }
    &.__item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #d8d8d8;
      }
      &:hover {
        .invite-user__table.__actions {
          .btn-custom__delete {
            opacity: 1;
          }
        }
      }
    }
    &.__value {
      white-space: nowrap;
      &.__to {
        padding-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.__sent {
        display: flex;
        margin-right: 10px;
        justify-content: flex-end;
        flex: 1 0 auto;
        font-size: 10px;
        font-weight: bold;
        text-align: right;
        text-transform: uppercase;
        color: $discrete-text;
      }
      span {
        padding-right: 2px;
      }
    }
    &.__actions {
      display: flex;
      justify-content: flex-end;
      flex: 0 auto;
      .btn-custom__delete {
        display: flex;
        align-items: center;
        opacity: 0.2;
        transition: 0.2s;
      }
    }
  }
}
