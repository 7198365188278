.our-agreements-section {
  padding-left: 10px;
  .agreement-card {
    &__title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    &__body {
      position: relative;
      border: 1px dashed $light-gray;
      padding: 10px;
      &__row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 10px 5px 0px 10px;
        height: 32px;
        &__link {
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &__updated-description {
        color: $mid-gray;
        font-size: 12px;
        font-style: italic;
        margin: 20px 10px 10px 10px;
      }
      .absolute-upload-button {
        position: absolute;
        top: 10px;
        right: 20px;
      }
    }

    &-item {
      &__view {
        margin: 20px 0px 0px 10px;
      }
      &__edit {
        padding: 10px;
        border-radius: 3px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        margin-bottom: 20px;
        &__input {
          margin-right: 20px;
          label.label {
            line-height: unset;
            margin: 0px 0px 5px 0px;
          }
          input.input[name="name"] {
            width: 180px;
            padding-left: 15px;
          }
          input.input[name="documentNumber"] {
            width: 130px;
            padding-left: 15px;
          }
        }
        .editBlock_date-picker__item {
          .ant-picker.editBlock_date-picker__field {
            min-width: 135px;
            width: 135px;
          }
          .editBlock_field__label {
            min-width: 135px;
            padding-left: 0px;
          }
        }
        .editBlock_select__item {
          .editBlock_field__label {
            padding-left: 0px;
          }
          .editBlock_select__field > .ant-select-selector {
            padding-left: 10px;
          }
        }
        &__delete-button {
          padding-right: 0px;
        }
        &__save-button {
          margin-right: 10px;
        }
      }
    }
  }
}
