@include for-size(xl) {
  .public-rfps {
    .requests-card {
      &_head,
      &_body {
        &__column {
          &.__rfp-name {
            display: none;
            visibility: hidden;
          }
          &.__category {
            width: 17%;
          }
          &.__region {
            width: 12%;
          }
          &.__published-at {
            width: 12%;
            padding-right: 20px;
          }
          &.__time-left {
            width: 16%;
          }
          &.__customers {
            width: 18%;
            padding-right: 20px;
          }
          &.__project-name {
            width: 25%;
          }
        }
      }
    }
  }
}

@include for-size(l) {
  .public-rfps {
    .requests-card {
      &_head,
      &_body {
        &__column {
          &.__project-name,
          &.__rfp-name {
            display: none;
            visibility: hidden;
          }
          &.__category {
            width: 25%;
          }
          &.__region {
            width: 21%;
          }
          &.__published-at {
            width: 10%;
          }
          &.__time-left {
            width: 18%;
          }
          &.__customers {
            width: 30%;
          }
        }
      }
    }
  }
}

@include for-size(ml) {
  .public-rfps {
    .requests-card {
      &_head,
      &_body {
        &__column {
          &.__project-name,
          &.__rfp-name,
          &.__customers {
            display: none;
            visibility: hidden;
          }
          &.__category {
            width: 25%;
          }
          &.__region {
            width: 25%;
          }
          &.__published-at {
            width: 23%;
          }
          &.__time-left {
            width: 27%;
          }
        }
      }
    }
  }
  @include for-size(xs) {
    .public-rfps {
      .requests-card {
        &_head,
        &_body {
          border-style: solid;
          border-width: 0 0 1px 0;
          border-color: $off-white;
          border-radius: 0;

          &__column {
            &.__project-name,
            &.__rfp-name,
            &.__customers,
            &.__turnover {
              display: none;
              visibility: hidden;
            }
            &.__category {
              width: 32%;
            }
            &.__region {
              width: 30%;
            }
            &.__published-at {
              width: 14%;
            }
            &.__time-left {
              width: 24%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .public-rfps {
    &__search-select {
      padding: 20px 0;
    }
    &__selections {
      width: 100%;
    }
  }
}
