.procurement-timeline-date-modal {
  &__wrapper > .modal-ent__content > div {
    padding: 3px 40px 40px 40px;
    height: 350px;
    .pretext {
      margin-right: 10px;
      text-transform: lowercase;
    }
  }
  &__timeline-step {
    width: 68px;
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    padding: 10px 5px 10px 10px;
    border-radius: 3px;
    font-size: 12px;
    opacity: 0.2;
    &:not(:last-child) {
      margin-right: 10px;
    }
    & > svg {
      margin-right: 5px;
    }
    & > svg > path {
      fill: $black;
    }
    &.__disabled {
      & > svg > path {
        fill: $black;
      }
    }
    &.clickable {
      opacity: 1;
      &:hover {
        background: $link;
        color: $white;
        & > svg > path {
          fill: $white;
        }
      }
    }
  }
}
