.saying-beaver {
  &_wrapper {
    background: $white;
    border-radius: 3px;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 200px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    padding: 20px 15px 0px 15px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__message {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  &__button {
    margin: 20px 0px;
    letter-spacing: -0.02em;
  }

  &__beaver {
    height: 133px;
  }
}
