@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .organizations {
    padding: 20px;
  }

  .organization-card {
    &_head {
      display: none;
    }
    &_body {
      flex-wrap: wrap;
      width: 100%;
      border: 1px solid #afafaf;
      margin-bottom: 20px;
      padding-top: 20px;
      &__name {
        &.__wrapper {
          width: 100%;
          margin-bottom: 20px;
          .empty {
            height: 40px;
            width: 40px;
            min-width: 40px;
            border: 1px dotted $light-gray;
          }
        }
        &.__logo {
          height: 40px;
          width: 40px;
          min-width: 40px;
        }
        &.__title {
          margin-right: 0;
          & > svg {
            width: 30px;
          }
        }
      }
      &__indicator {
        &.__wrapper {
          width: 100%;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        &.__status {
          margin: 0px 0px 0px 10px;
        }
        &.__sub-status {
          display: none;
        }
        &.__rating,
        &.__loading {
          width: calc(50% - 44px);
          margin: 0;
          margin-left: auto;
        }
        &.__rating {
          min-width: 130px;
        }
        &.__loading {
          margin-top: 5px;
        }
      }
    }
  }
}
