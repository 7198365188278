@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .first-setup {
    &_selection {
      &__wrapper {
      }
      &__item-wrap {
        .selection__title {
          margin-bottom: 10px;
        }
        .multi-select__title {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .main-content {
    &_wrapper {
      .layout-content {
        &_wrapper {
          padding-left: 0;
          padding-right: 0;
        }
        &_inner-wrap {
          padding-left: 0;
        }
      }
    }
  }

  .content-block {
    &_wrapper {
      padding: 10px;
    }
  }

  .ant-dropdown {
    max-width: 240px;
    .ant-dropdown-menu-item span {
      white-space: normal;
    }
  }
}

@media (min-width: 320px) and (max-width: 991.98px) {
  .main-content_wrapper {
    padding-top: 56px;
  }
}
