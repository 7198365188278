.our-projects {
  background: $white;
  margin-bottom: 40px;
  border-radius: 3px;
  padding: 40px;
  &-table-title {
    margin-top: 50px;
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      & > svg {
        margin: 1px 20px 0px 0px;
        width: 40px;
        height: 40px;
      }
    }
    &_title {
      line-height: 31px;
    }
  }
  &_head__btn {
    margin-left: auto;
  }
  .empty-zone_wrapper {
    background-color: $off-white;
    padding: 45px;
    span {
      font-size: 16px;
    }
  }
  &_content {
    margin: -32px 220px 80px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.projects-tab {
  .projects-table {
    &_item_row {
      display: flex !important;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
    &_item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 10px 0px 10px 10px;
      margin-left: -10px;
      border: 1px solid transparent;
      border-radius: 3px;
      transition: 0.3s;
      color: $black;
      cursor: pointer;
      &:hover {
        border-color: rgba($link, 0.3);
        color: $link;
        .project-label {
          &_wrapper {
            border-color: $link !important;
          }
          &_name {
            color: $link !important;
          }
          &_code {
            background-color: $link !important;
          }
        }
        .icon-table,
        .icon-table svg {
          fill: $link !important;
        }
        .badge sup {
          background-color: $link;
        }
      }
      &_dropdown {
        &__wrapper {
          display: block;
        }
        &__form {
          margin-top: 10px;
          display: flex;
          .remove-btn {
            cursor: pointer;
          }
        }
      }
      &__indicator {
        &.__wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 96px;
          & > span {
            margin-left: 0px !important;
          }
          &.__left-border {
            border-left: 1px solid $light-gray;
          }
        }
      }
    }
  }

  .project-label {
    &_wrapper {
      display: flex;
      margin-right: auto;
      border: 1px solid $purple;
      border-radius: 3px;
      height: 22px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      white-space: nowrap;
      user-select: none;
      &.__full {
        .project-label_name,
        .project-label_code {
          max-width: 150px;
        }
      }
    }
    &_name,
    &_code {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px 3px;
      height: 100%;
      line-height: 20px;
      text-align: center;
      &.__empty {
        min-width: 10px;
      }
    }
    &_name {
      color: $purple;
    }
    &_code {
      color: $white;
      background-color: $purple;
    }
  }
  .empty-projects-description {
    font-size: 16px;
  }
}

.project-create-edit-modal {
  .modal-ent__header {
    .project-label_wrapper {
      margin-right: unset;
    }
  }
  &__content {
    padding: 40px;
    .multiselect-custom_wrapper {
      margin: 40px 0px 20px 0px;
    }
    .multiselect-custom_title,
    .panel-content .select-item {
      text-align: left;
    }
  }
}

.procurement-create-edit-modal {
  &__content {
    padding: 40px;
    input {
      height: 38px;
      width: 100%;
      &:disabled {
        background-color: $white-gray;
      }
    }
    .name-info {
      margin-top: 10px;
      font-size: 12px;
      text-align: left;
      color: $mid-gray;
    }
    label {
      text-align: left;
      margin-top: 0px;
      margin-left: 0px;
      padding-left: 0px;
    }
    .editBlock_select__item {
      margin: 0px;
      & > .editBlock_select__field {
        width: 100%;
        & > .ant-select-selector {
          height: 38px;
          padding-left: 10px;
        }
      }
    }
  }
}

.rfp-create-modal {
  &__content {
    padding: 40px;
  }
  &_description {
    font-size: 16px;
  }
  &_create-block {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    &_title {
      font-size: 10px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
    &_data {
      margin-top: 20px;
      padding: 10px;
      border: 1px dashed $light-gray;
      flex-grow: 2;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      .ant-select.__show-arrow {
        & > .ant-select-selector {
          padding-left: 0px;
          border-radius: 3px;
          max-width: 350px;
          overflow: hidden;
          text-overflow: ellipsis;
          align-items: center;
          & > .ant-select-selection-item {
            display: block;
          }
        }
      }
      .project-label {
        &_wrapper {
          &.__full.__wide-name {
            .project-label_name {
              max-width: 200px !important;
            }
          }
        }
      }
    }
    &_button {
      align-self: flex-end;
    }
  }
}
