.tab {
  border: none;
  list-style: none;
  font-size: 14px;
  text-transform: uppercase;
  color: $mid-gray;
  background: transparent;
  padding: 0 0 5px 0;
  margin-right: 30px;
  border-bottom: 2px solid transparent !important;
  a {
    color: $black;
    span {
      margin-left: 10px;
      color: $mid-gray;
    }
    p {
      margin: 0;
      display: inline;
    }
  }

  &:last-child {
    margin-right: 0;
  }
  &[disabled] {
    color: $light-gray;
    cursor: not-allowed;
    a {
      color: $light-gray;
      cursor: not-allowed;
    }
    &:hover {
      color: $light-gray;
      cursor: not-allowed;
      a {
        color: $light-gray;
        cursor: not-allowed;
      }
    }
  }
  &__active {
    border-bottom: 2px solid $link !important;
    color: $black !important;
    font-weight: bold;
    &[disabled] {
      border-bottom-color: $light-gray !important;
    }
  }

  &__bold {
    color: $black !important;
    font-weight: 700;
  }
  &.red-count > a > span {
    color: $red;
  }
}

.lock-value-tab {
  &-wrapper {
    display: inline-flex !important;
  }
  &-wrapper:not(:last-child) {
    margin-right: 20px;
  }
  &-icon {
    margin: -3px 0px 5px -16px !important;
  }
}

.document-revision-tabs {
  &,
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 3px;
  }
  & > button.tab {
    font-weight: 400;
    text-transform: none;
  }
  & > button.btn-transparent {
    margin-top: -4px;
    margin-left: -20px;
  }
}
