.modal-hide-offers-switch {
  &__wrapper {
    .modal-ent__header > button > svg {
      margin: 0px;
    }
    .modal-ent__content {
      text-align: left;
    }
  }
}
