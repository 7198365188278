.addable-field {
  &_wrapper {
    width: 236px;
    flex-shrink: 0;
  }
  &_input {
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    height: 40px;
    border-radius: 20px !important;
    border: none !important;
    &:hover,
    &:active {
      border: none !important;
    }
    input {
      font-size: 16px !important;
      padding: 0px 5px 0px 10px !important;
      border-radius: 10px !important;
    }
    &.__small {
      height: 32px;
      border-radius: 16px !important;
      input {
        font-size: 12px !important;
      }
    }
  }
  &_button {
    border: none !important;
    height: 20px !important;
    min-width: 20px !important;
    width: 20px !important;
    padding: 0px !important;
    margin-right: 5px;
    img {
      margin-top: -10px;
    }
    &.__small,
    &.__small > svg {
      height: 16px !important;
      min-width: 16px !important;
      width: 16px !important;
      img {
        margin-top: -8px;
      }
    }
    &.__small > svg {
      margin-bottom: 4px;
    }
  }
}

.rich-field {
  &__wrapper {
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 3px;
    &.view_mode {
      box-shadow: none;
      border: 1px dotted $mid-gray;
    }
  }
  &__toolbar.rdw-editor-toolbar {
    background-color: $off-white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    margin: 0px;
    min-height: 50px;
    .rdw-option-wrapper {
      min-width: 30px;
      height: 28px;
      border-radius: 3px;
      &:hover {
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
      }
    }
    .rdw-dropdown-wrapper:hover {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
    }
  }
  &__editor {
    padding: 0px 10px;
    cursor: text;
    min-height: 150px;
    word-break: normal;
    font-size: 16px;
    &.view_mode {
      cursor: default;
      margin-left: 10px;
      min-height: 50px;
    }
  }
}

.color-row {
  display: flex;
  &_wrapper {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
  }
  &_title {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: $black;
    margin: 10px 0px -10px 70px;
  }
  &.top {
    border-top: 1px dashed $light-gray;
  }
  &.bottom {
    border-bottom: 1px dashed $light-gray;
  }
  &.topbottom {
    border-top: 1px dashed $light-gray;
    border-bottom: 1px dashed $light-gray;
  }
  &_strip {
    width: 10px;
  }
  &.filled,
  &_strip {
    flex-shrink: 0;
    &.blue {
      background-color: $link;
    }
    &.green {
      background-color: $green;
    }
    &.red {
      background-color: $red;
    }
    &.purple {
      background-color: $purple;
    }
    &.orange {
      background-color: $orange;
    }
  }
}

.field {
  &.__small {
    & > input {
      height: 34px !important;
    }
  }
}
