.subscriptions {
  &__wrapper {
    @include for-size(sm) {
      max-width: unset;
      margin: 0;
    }
    .header-and-offer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 20;
      position: relative;

      .ant-tabs {
        max-width: 100vw;
      }

      .offer {
        display: flex;
        align-items: center;

        .blob {
          color: $red;
          border: 1px solid $red;
          border-radius: 3px;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 1;
          padding: 10px;
          margin-right: 10px;
        }

        .text {
          .main {
            color: $red;
          }
          .info {
            font-size: 12px;
            color: #858585;
          }
        }
      }
    }

    .modal-ent {
      &__header {
        padding: 40px 40px 20px 40px;
      }
      @include for-size(md) {
        .df-row-jsb {
          flex-flow: column nowrap;

          .mobile-column:last-of-type {
            margin-top: 40px;
          }
        }
      }
    }
  }
  &__title {
    font-size: 32px;

    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    width: 100%;
  }
  &__subtitle {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__tabs.ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 0px;

      &-list {
        padding-left: 3px;
      }
      &-wrap {
        padding-top: 10px;
      }

      @include for-size(xs) {
        &-operations {
          display: none !important;
        }
      }
    }
    .ant-tabs {
      overflow: visible;
      &-nav {
        &:before {
          display: none;
        }
        &-wrap {
          @include for-size(xs) {
            padding-left: 17px;
          }
        }
      }
      &-ink-bar {
        display: none;
      }
      &-tab {
        padding: 20px 30px;
        font-size: 20px;
        font-weight: 700;
        border-radius: 3px 3px 0 0;
        background: linear-gradient(
          0deg,
          rgba(234, 234, 234, 1) 0%,
          rgba(245, 245, 245, 1) 3%,
          rgba(245, 245, 245, 1) 3%,
          rgba(245, 245, 245, 1) 100%
        );
        text-align: center;
        &:hover {
          color: $black;
        }
        &-active {
          background: $white;
          filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16));
          border-radius: 3px 3px 0px 0px;

          .ant-tabs-tab-btn {
            color: $black !important;
            font-weight: 700 !important;
          }
        }
      }
      &-content-holder {
        display: none;
      }
    }
  }
  &__items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 40px 80px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    background-color: $white;
    @include for-size(md) {
      flex-flow: column nowrap;
      padding: 20px;
    }

    &__regular_wrapper {
      display: flex;
      flex-flow: row nowrap;
      @include for-size(md) {
        flex-flow: column nowrap;
        border: none;
        width: 100%;
      }
      @include for-size(md) {
        width: 100% !important;
      }
      justify-content: flex-start;
      border: 1px solid $light-gray;
      border-radius: 3px;
      &.__2 {
        width: 40%;
      }
      &.__3 {
        width: 60%;
      }
      &.__4 {
        width: 80%;
      }

      & > div.subscription:not(:last-child) {
        border-right: 1px solid $light-gray;
        @include for-size(md) {
          border-right: none;
        }
      }
      &.__options,
      &.__options > div.subscription:not(:last-child) {
        border: none;
      }
    }
  }
  &__summary-subscription {
    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
    }
    &__sum {
      font-size: 24px;
      font-weight: 700;
    }
    &__note {
      font-size: 12px;
      color: $discrete-text;
    }
  }
  &__invoicing-address {
    margin-top: 30px;
    &__description {
      color: $discrete-text;
      font-size: 12px;
    }
    .input {
      height: 35px;
    }
    button {
      margin-top: 1.5px;
      margin-left: 20px;
    }
  }
}
.subscriptions-modal {
  & > .modal-ent__header {
    padding-bottom: 0px;
  }
  & > .modal-ent__content {
    padding: 40px;
    @include for-size(sm) {
      padding: 20px 20px 60px 20px;
    }

    text-align: left;
    .input {
      height: 35px;
    }
    .custom-switch_label > a {
      text-decoration: underline;
      color: $link;
    }
  }
  &__button {
    margin-left: auto;
  }
  &__buttons {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }
}

.subscription {
  &.__2 {
    width: 50%;
    max-width: 50%;
  }
  &.__3 {
    width: 33.3%;
    max-width: 33.3%;
  }
  &.__4 {
    width: 25%;
    max-width: 25%;
  }
  @include for-size(xs) {
    &.__2,
    &.__3,
    &.__3 {
      width: 100%;
      max-width: unset;
    }
  }
  @include for-size(md) {
    width: 100%;
    max-width: 100%;
    border: 1px solid $light-gray !important;
    margin-bottom: 20px;
  }
  padding-top: 25px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  & > div > svg {
    width: 30px;
    height: 30px;
    margin: 0px 0px 10px 20px;
    & > path {
      fill: $black;
    }
  }
  & > div.__active > svg > path {
    fill: $link;
  }
  &__title {
    font-size: 16px;
    @include for-size(sm) {
      font-size: 26px;
      line-height: 27px;
      height: auto;
    }
    line-height: 16px;
    font-weight: 700;
    height: 40px;
    text-transform: uppercase;
    padding: 0px 20px;
    margin-bottom: 20px;
    color: $black;
    &.__active {
      color: $blue;
    }
    & > svg {
      height: 30px;
      width: 30px;
    }
  }
  &.__options,
  &.__options.__contact-us {
    border: none;
  }
  &__description {
    font-size: 14px;
    padding: 0px 20px;
    @include for-size(sm) {
      font-size: 16px;
    }
  }
  &__price {
    display: flex;
    flex-flow: row nowrap;
    .price-info {
      flex-flow: row nowrap;
      align-items: center;
      .price-extras {
        display: flex;
        flex-direction: column;
        line-height: 1;
        .special-offer {
          width: fit-content;
          background: $gold;
          text-transform: uppercase;
          margin-top: 5px;
          padding: 3px 5px 3px 5px;
          font-weight: bold;
          border-radius: 3px;
        }
        .special-offer-terms {
          margin-top: 4px;
        }
      }
    }
    @include for-size(sm) {
      display: flex;
      font-size: 16px;
      height: 80px;
      .price {
        padding-right: 0 !important;
        @include for-size(xs) {
          font-size: 50px;
        }
      }
    }
    align-items: center;
    font-size: 12px;
    height: 55px;
    padding: 0px 20px;
    margin: 30px 0px 10px 0px;
    .price-info {
      display: flex;
    }
    .amount {
      font-size: 34px;
      line-height: 34px;
      display: flex;
      flex-direction: column;
      @include for-size(xs) {
        font-size: 50px;
        line-height: 50px;
      }

      .normal-price {
        font-size: 20px;
        line-height: 20px;
        margin-left: 10px;
        text-decoration: line-through;
      }
    }
    .contact-us {
      font-size: 16px;
      @include for-size(sm) {
        font-size: 24px;
      }
    }
  }
  &__options {
    margin: 20px 0px 0px 20px;
    padding-right: 20px;
    flex-grow: 2;
    padding-left: 12px;
    &.__active > li::marker {
      color: $blue;
    }
    & > li {
      word-break: break-word;

      &.hide-bullet {
        color: red;
      }

      @media only screen and (max-width: 1400px) {
        .truncate-me {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      @include for-size(sm) {
        .truncate-me {
          white-space: normal;
        }
      }
    }
  }
  &__option {
    font-size: 12px;
    @include for-size(sm) {
      font-size: 16px;
    }
  }
  &__coming-soon-star {
    position: relative;
    top: -22px;
    display: flex;
    align-items: center;
    &__text {
      margin-left: -50%;
      color: $white;
      font-weight: 800;
      font-size: 10px;
      line-height: 13px;
      white-space: break-spaces;
      text-transform: uppercase;
      text-align: center;
    }
  }
  &__status {
    height: 56px;
    background-color: $light-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    &.__active {
      background-color: $green-light-opaque;
    }
    @media only screen and (max-width: 1200px) {
      button {
        span {
          font-size: 14px;
          @include for-size(xs) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
