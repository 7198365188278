@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .find-suppliers {
    &__search-select {
      padding: 20px 0;
    }
    &__selections {
      width: 100%;
    }
    &__inp-name {
      .input {
        width: 100%;
      }
    }
    &_tab__item {
      .multiselect-custom_wrapper:not(:last-child) {
        margin-bottom: 20px;
      }
      .multiselect-custom_title {
        margin-bottom: 5px;
        & > p {
          margin-bottom: 0px;
        }
      }
    }
    &__language {
      margin-bottom: 20px;
    }
    .decorative-search {
      margin-bottom: 0px;
      & > svg {
        width: 20px;
      }
      & > h3 {
        margin-left: 5px;
      }
      &__buttons {
        & > button {
          height: 28px;
          margin-left: 10px !important;
          padding: 0px 5px;
        }
        .custom-switch_wrapper {
          & > button {
            margin-left: 10px !important;
          }
        }
      }
    }
  }

  .first-setup_selection {
    &__wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
    &__item-wrap {
      margin-right: 15px;
      margin-bottom: 15px;
      .multiselect-custom_title,
      .blob-title,
      .first-setup_selection__item {
        margin-bottom: 5px !important;
        & > p {
          margin-bottom: 0px;
        }
      }
    }
    &__wrapper > .find-suppliers__inp-name > label {
      margin-bottom: 5px !important;
    }
  }
}
