.comment-modal {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
  margin-top: 80px;
  &__base {
    background: rgba(0, 0, 0, 0.15);
  }
  &__text-field {
    margin-bottom: 35px;
    text-align: left;
    & > textarea {
      padding: 10px !important;
    }
  }
}
