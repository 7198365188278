.feed {
  margin-bottom: 40px;

  &-header {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px 20px 40px;

      @include for-size(md) {
        margin-bottom: 20px;
      }

      @include for-size(ml) {
        flex-direction: column;
        align-items: flex-start;
      }

      @include for-size(xs) {
        padding: 0px;
        margin-top: 10px;
        align-items: flex-start;
      }

      h3 {
        font-size: 24px;
        text-transform: uppercase;
        margin: 0 0 0 20px;
        line-height: 40px;
        white-space: nowrap;

        @include for-size(xs) {
          font-size: 16px;
          line-height: 1.5em;
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: flex-start;
      @include for-size(ml) {
        margin-bottom: 20px;
      }

      &__main-wrapper {
        display: flex;
        flex-direction: row;
      }

      &__sub-text {
        margin-left: 60px;
      }
    }

    &__description {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      @include for-size(l) {
        flex-direction: column;
      }

      &__item {
        &-wrapper {
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          &:not(:last-of-type) {
            margin-right: 40px;
          }

          @include for-size(l) {
            margin-bottom: 10px;
          }
        }
        &-target {
          font-weight: bold;
          margin: 0 5px 5px 0;
          text-transform: uppercase;
        }

        &-maintext {
          display: flex;
          align-items: center;
          &__fake-button {
            display: inline-flex;
            border-radius: 3px;
            margin-right: 5px;
            padding: 4px 5px 1px 5px;

            svg {
              margin-right: 5px;
            }

            &.__buyer {
              color: $blue;
              border: 1px solid $blue-less-opaque;
            }
            &.__seller {
              color: $green;
              border: 1px solid $green-less-opaque;
            }
          }
        }
      }
    }
  }

  .feed-post {
    &__wrapper {
      background: $white;
      border-radius: 3px;
      padding: 20px 40px;
      margin-bottom: 10px;
      @include for-size(xs) {
        padding: 20px 20px;
      }
      &.specific-post-divider {
        margin-bottom: 20px;
        border: 1px solid $light-gray;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
      }
    }

    &-edit {
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & > span {
          color: $mid-gray;
          font-size: 12px;
        }
      }

      &__main-info {
        display: flex;

        @include for-size(xs) {
          flex-flow: column nowrap;
          & > div {
            margin-left: 0px !important;
            max-width: 100% !important;
          }
        }

        .editBlock_date-picker__item {
          margin-right: 10px;
        }

        .input,
        .editBlock_date-picker__field {
          height: 40px;
          margin: 0px;
        }

        // .form-field_wrapper {
        //   &.location-input {
        //     .modal-maps__wrapper {
        //       left: -150%;
        //       width: 270%;
        //       height: 300px;
        //       @include for-size(xs) {
        //         left: -10%;
        //         width: 120%;
        //         height: 300px;
        //       }
        //     }
        //     input {
        //       width: 200px;
        //     }
        //   }
        //   input {
        //     padding: 10px;
        //   }
        // }
      }

      &__description {
        textarea {
          padding: 10px;
          line-height: 18px;
          margin: 0px;
          @include for-size(xs) {
            height: 74px !important;
          }
        }
      }
    }

    &-view {
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        flex-flow: row wrap;
        @include for-size(xs) {
          justify-content: space-between;
          margin-bottom: 20px;
          & > a {
            order: 0;
          }
          & > time {
            margin-top: 10px;
            order: 2;
          }
          & > button {
            margin-left: 0px !important;
          }
          & > button:first-of-type {
            order: 3;
            margin-top: 10px;
            padding-right: 0px;
          }
          & > button:last-of-type {
            margin-top: 0px;
            order: 1;
          }
        }
      }
      &__share-button {
        color: $black;
        border-color: $black;
        margin-left: auto;
        &.__image-right svg {
          margin-top: -7px;
        }
      }
      &__main-info {
        display: flex;
        align-items: center;
        margin: 20px 0px 20px 0px;

        @include for-size(xs) {
          flex-flow: column nowrap;
          align-items: flex-start;
          margin: 0px;
          & > div {
            margin-top: 10px;
          }
          & svg {
            margin-left: 0px !important;
          }
        }

        &__title {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0;
        }
        &__location.hide-info {
          text-transform: uppercase;
        }
        &__date {
          text-transform: capitalize;
        }
      }
      &__description {
        font-size: 16px;
        @include for-size(xs) {
          margin-top: 10px;
        }
      }
      &__reaction {
        display: flex;
        align-items: center;
        margin-top: 20px;
        @include for-size(xs) {
          flex-flow: column nowrap;
          align-items: flex-start;
        }
        &__description {
          color: $green;
          font-size: 12px;
          margin: 0px 40px 0px 10px;
          @include for-size(xs) {
            margin: 10px 0px;
          }
        }
        &__hand-badge {
          height: 22px;
          width: 22px;
          background-color: $green;
          border-radius: 50%;
          border-color: $green;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            height: 12px;
            width: 12px;
          }
        }
        &__companies {
          margin-left: 10px;
          color: $green;
          font-size: 12px;
        }
      }
    }
  }
}
