.credit-report-company-widget {
  &__title,
  &__title-number {
    font-size: 24px;
    font-weight: 800;
    margin: 0px;
  }
  &__title-number {
    color: $mid-gray;
    margin: 0px 20px;
  }
}

.credit-report-companies-upsale-component {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 40px;
  & > img {
    width: 230px;
    height: auto;
    margin-right: 40px;
  }
  &__title {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
  }
  &__button.btn-custom_main.btn-transparent {
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 14px;
    margin-top: 20px;
  }
}
