.language {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);

  width: 200px;
  display: flex;
  align-items: center;
  position: relative;

  & > svg {
    position: absolute;
    left: 20px;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    height: 52px !important;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;
    padding: 0 20px 0 60px !important;
    border-radius: 3px;
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;
  }

  .ant-select-arrow {
    right: 30px;
  }

  .anticon-down {
    width: 19px;
    height: 13px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}
