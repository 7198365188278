.our-offers {
  background: $white;
  border-radius: 3px;

  &-table-title {
    margin-top: 50px;
  }

  .empty-data {
    text-align: center;
    font-weight: 700;
  }

  .content-block {
    &_header {
      margin-bottom: 0px;
      padding: 40px 45px;

      svg {
        margin: 2px 20px 0px 0px;
        width: 40px;
        height: 36px;
      }
    }

    &_wrapper {
      min-height: 50vh;

      .not-bold h3 {
        font-weight: 400;
      }
    }

    &_title {
      line-height: 31px;
    }
  }

  /* Setting column widths as common variables for this table */
  $requests-card-column-wide: 25%;
  $requests-card-column-medium: 20%;
  $requests-card-column-narrow: 15%;
  $requests-card-column-last-filler: 15%;

  .requests-card {
    &_head {
      font-size: 12px !important;
      text-transform: uppercase;
      font-weight: bold;

      &__empty {
        &.__wrapper {
          flex-basis: $requests-card-column-medium;
          width: auto;
        }
      }

      &__project {
        &.__wrapper {
          flex-basis: $requests-card-column-medium;
          width: auto;
        }
      }

      &__title {
        &.__wrapper {
          flex-basis: $requests-card-column-medium;
          width: auto;
        }
      }

      &__name {
        &.__wrapper {
          flex-basis: $requests-card-column-medium;
          width: auto;
          padding-right: 0;
          margin-right: 0;
        }
      }

      &__column {
        &.__wrapper {
          flex-basis: $requests-card-column-narrow;
          width: auto;
        }
      }
    }

    &_body {
      padding: 10px;

      &__column {
        &.__wrapper {
          flex-basis: $requests-card-column-medium;
          width: auto;
          padding-top: 5px;
          padding-right: 10px;
          font-size: 14px;
          line-height: 25px;
          align-items: flex-start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.date {
            font-size: 12px;
            flex-basis: $requests-card-column-narrow;
          }
        }
      }

      &:hover {
        .requests-card_body__project {
          &.__wrapper {
            .text-badge {
              border-color: $blue !important;
              color: $blue !important;
            }
          }
        }
      }

      &__indicator {
        &.__wrapper {
          margin-top: 5px;
          flex-basis: $requests-card-column-medium;
          width: auto;
        }
      }

      &__project {
        &.__wrapper {
          flex-basis: calc($requests-card-column-medium - 20px);
          margin-right: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .text-badge {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &__name {
        &.__wrapper {
          font-size: 14px;
          flex-basis: calc($requests-card-column-medium - 20px);
          margin-right: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0;
        }
      }
      &__status {
        &.__wrapper {
          font-size: 10px;
          text-transform: uppercase;
          .status-read {
            color: $green;
          }
        }
      }
    }
  }

  .requests-card_body {
    &:hover {
      .requests-card_body__project.__wrapper {
        .text-badge {
          border-color: $blue !important;
          color: $blue !important;
        }
      }

      .requests-card_body__status.__wrapper {
        color: $blue !important;

        svg {
          path {
            stroke: $blue !important;
          }
        }

        .green,
        .red {
          color: $blue !important;
          border-color: $blue !important;
        }

        .status-read {
          color: $blue !important;
        }

        .status-submitted {
          color: $blue !important;
        }
      }

      .requests-card_body__indicator.__wrapper {
        .text-badge {
          border-color: $blue !important;
          color: $blue !important;
        }
      }
    }
  }
}
