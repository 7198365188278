.dropdown-ellipsis {
  &__list > ul {
    padding: 10px 0px;
  }

  &__row {
    padding: 10px;
    display: flex;
    align-items: center;

    &.thin-row {
      padding: 2px 10px;
    }

    &.tight-row {
      padding: 10px 4px;
    }

    svg {
      margin-right: 10px;
    }

    span {
      color: $link;
      font-size: 14px;
    }
  }
}
