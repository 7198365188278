.content-block {
  &_wrapper {
    min-height: 50vh;
    margin-bottom: 40px;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
  }

  &_header {
    display: flex;
    align-items: center;
    margin-bottom: 70px;
    & > svg {
      margin-right: 30px;
    }
  }

  &_title {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 800;
  }
}

.sub-page {
  &__header {
    &.__wrapper {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
      background-color: #fff;
    }

    &.__brand {
      width: 200px;
    }
  }

  &__footer {
    &.__wrapper {
      padding: 20px;
      display: flex;
      justify-content: center;
      flex: 0 0 auto;
      box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2);
      background-color: #fff;
    }
  }
}

.info-chunk {
  font-size: 12px;
  background: $green;
  display: inline-block;
  border-radius: 3px;
  color: white;
  line-height: 1;
  padding: 5px;
  margin: 0;
  &:before {
    content: "ⓘ";
    margin-right: 12px;
    font-weight: bold;
  }
}
