.settings-page {
  &_wrapper {
    background-color: $off-white;
    padding-bottom: 100px;
    overflow: hidden;
  }
  &__desc {
    font-size: 14px;
    text-transform: uppercase;
  }

  &__header {
    .header {
      @include for-size(md) {
        flex-direction: column;
      }
    }
  }

  &__emergency-logout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    @include for-size(md) {
      flex-direction: column;
    }
    * {
      font-size: 12px;
      line-height: 12px;
    }

    .info-text {
      display: flex;
      margin-right: 20px;
      align-items: center;
      @include for-size(md) {
        margin-bottom: 20px;
      }
    }
  }

  &__title {
    margin-bottom: 80px;
    .custom-title {
      letter-spacing: -2px;
      text-align: center;
    }
    .custom-text {
      text-align: center;
    }
  }

  &__form {
    h6 {
      margin-bottom: 10px;
    }
    .settings-form {
      .field {
        margin-bottom: 20px;
        .label {
          margin: 0 0 10px 20px;
          font-size: 14px;
          line-height: 18.2px;
        }
      }
    }
  }

  &__info {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    @include for-size(md) {
      width: 100%;
      padding: 0 20px;
    }
    input {
      width: 100%;
      margin-bottom: 5px;
    }
    span {
      display: block;
      margin-bottom: 80px;
    }
    .editBlock_select__item {
      margin: 0px;
      .editBlock_select__field {
        width: 100%;
        > span {
          margin: 0;
        }
        .ant-select-selection-item {
          margin-bottom: 0px;
        }
      }
    }

    .payment-title {
      margin-top: 49px;
      margin-bottom: 20px;
    }

    .form {
      input {
        width: 100%;
      }
    }
  }
}
