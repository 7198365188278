.modal-smart-templates {
  &_wrapper {
    width: 100%;
    margin: 40px;
    max-width: calc(100vw - 80px);
    @include for-size(xs) {
      margin: 10px;
      max-width: calc(100vw - 20px);
    }
  }
  background-color: $off-white;
  & > div {
    padding: 0px;
  }
  &__tabs.ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 0px;

      &-list {
        padding-left: 3px;
      }
      &-wrap {
        padding-top: 5px;
      }

      @include for-size(xs) {
        &-operations {
          display: none !important;
        }
      }
    }
    .ant-tabs {
      overflow: visible;
      &-nav {
        &:before {
          display: none;
        }
        &-wrap {
          @include for-size(xs) {
            padding-left: 17px;
          }
        }
      }
      &-ink-bar {
        display: none;
      }
      &-tab {
        padding: 20px 30px;
        font-size: 20px;
        font-weight: 700;
        border-radius: 3px 3px 0 0;
        background: linear-gradient(
          0deg,
          rgba(234, 234, 234, 1) 0%,
          rgba(245, 245, 245, 1) 3%,
          rgba(245, 245, 245, 1) 3%,
          rgba(245, 245, 245, 1) 100%
        );
        text-align: center;
        &:hover {
          color: $black;
        }
        &-active {
          background: $white;
          filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16));
          border-radius: 3px 3px 0px 0px;

          .ant-tabs-tab-btn {
            color: $black !important;
            font-weight: 700 !important;
          }
        }
      }
      &-content-holder {
        display: none;
      }
    }
  }
  &__tab-content {
    background-color: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    padding: 0px 40px 40px 40px;
  }
  &__table {
    &-head,
    &-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      &-name {
        width: 59%;
      }
      &-number {
        width: 26%;
        padding: 20px 40px !important;
        text-align: right;
      }
      &-date {
        width: 15%;
      }
      &-actions {
        width: 40px;
      }
    }
    &-head {
      & > div {
        padding: 15px 0px;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
      }
      &-name {
        padding-left: 13px !important;
      }
    }
    &-row {
      &:nth-child(2n) {
        background-color: $off-white;
      }
      &:hover {
        background-color: $white-gray;
        .modal-smart-templates__table-row-name,
        .modal-smart-templates__table-row-number,
        .modal-smart-templates__table-row-date {
          color: $link;
        }
      }
      & > div {
        padding: 15px 0px;
      }
      &-name {
        padding-left: 20px !important;
      }
      &-actions > button:hover {
        svg > path {
          fill: $link;
        }
      }
    }
  }

  &__table,
  &__template {
    &-body {
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.18) inset;
      border-radius: 3px;
      padding: 3px;
      max-height: calc(100vh - 408px);
      overflow-y: auto;
    }
  }

  &__template {
    &-head {
      display: flex;
      flex-flow: row nowrap;
      padding: 10px 0px 40px 0px;
    }
    &-body {
      padding: 10px 20px 20px 20px;
    }
  }

  &__table {
    &-body {
      .null-state-wrapper {
        display: flex;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
