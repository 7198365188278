.rating-stars {
  &_wrapper {
    span {
      margin-right: 2px;
    }
  }
}

.credit-rating-score {
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  align-items: center;
  &.__small {
    margin-bottom: 0px;
  }
  .special {
    color: $red;
    display: flex;
    text-align: center;
    max-width: 80px;
    color: $red;
    font-size: 12px;
    font-weight: normal;
    flex-direction: column;
    align-items: center;

    &__count {
      &.__small {
        font-weight: bold;
        color: $red;
      }
    }
  }
  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    width: 100%;
    height: 100%;
    position: absolute;
    &.__small {
      font-size: 9px;
      font-weight: 400;
    }
  }
}

.extra-info-link {
  margin-top: 13px;
  a {
    font-size: 12px;
    color: $mid-gray;
  }
}

.medal-rating {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 70px;
}
