.add-dalux-link-modal {
  &__content {
    padding: 0 40px 40px 40px;
    .info-blob {
      flex: 2;
      padding-right: 40px;
    }
  }
  &__file-list {
    .ant-table-wrapper {
      .ant-table-header {
        .ant-table-thead {
          tr {
            th {
              border-bottom: none;
            }
          }
          .ant-table-cell {
            font-weight: 700;
            text-transform: uppercase;
          }
        }
      }
      .ant-table-tbody {
        overflow-y: scroll;
        max-height: 50vh;

        tr {
          td {
            border-bottom: none;
            &.ant-table-cell-row-hover {
              background: unset;
            }
          }
        }
        .ant-table-row.colored {
          background-color: $off-white;
        }
        .ant-table-cell.ant-table-cell-with-append {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }
        .ant-checkbox.ant-checkbox-checked > .ant-checkbox-inner {
          background-color: $link;
          border-color: $link;
        }
        .ant-checkbox.ant-checkbox-indeterminate > .ant-checkbox-inner::after {
          background-color: $link;
        }
      }
    }
  }
}
