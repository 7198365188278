.our-qualifications {
  background: $white;
  border-radius: 3px;
  &-table-title {
    margin-top: 50px;
  }
  .empty-data {
    text-align: center;
    font-weight: 700;
  }
  .content-block {
    &_header {
      margin-bottom: 0px;
      padding: 40px 45px;
      & > svg {
        margin: 2px 20px 0px 0px;
        width: 40px;
        height: 36px;
      }
    }
    &_wrapper {
      min-height: 50vh;
      .not-bold h3 {
        font-weight: 400;
      }
    }
    &_title {
      line-height: 31px;
      font-weight: 800;
    }
  }
  .contracts-project-label {
    padding-top: 14px;
  }
  .requests-card {
    &_head {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      &__column .contracts-project-label .ant-select-selector {
        padding-left: 0px;
      }

      &__empty {
        &.__wrapper {
          width: 30%;
          &.__wide {
            width: 42%;
          }
        }
      }
    }
    &_body {
      padding: 5px 10px 10px 10px;
      &__column {
        &.__wrapper {
          padding-top: 5px;
          font-size: 14px;
          line-height: 25px;
          align-items: flex-start;
        }
      }
      &__name,
      &__number {
        &.__wrapper {
          padding-top: 4px;
        }
      }
      &__empty {
        &.__wrapper {
          width: 30%;
          &.__wide {
            width: 42%;
          }
        }
      }
    }
  }
}
