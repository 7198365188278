.custom-upload {
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  &__body {
    display: flex;
    & > .ant-upload-select {
      order: 2;
    }
    & > .ant-upload-list .ant-upload-list-item {
      margin-top: 5px;
      max-width: 300px;
      .ant-upload-text-icon {
        display: none;
      }
      a {
        color: $link;
      }
    }
  }
}
