.project-startup-guide {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1;
  }
  .stepped-project-guide {
    display: inline-flex;
    flex-direction: row;
    @media (max-width: 990px) {
      flex-direction: column;
    }

    .step {
      padding: 20px;
      max-width: 20vw;
      @media (max-width: 990px) {
        max-width: 100%;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
      .title {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .emoji {
          font-size: 24px;
          line-height: 24px;
          margin-right: 10px;
          font-weight: bold;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 12px;
      }
      .video-instruction-link {
        margin-top: 20px;
      }
    }
    .active {
      border-radius: 3px;
      background: $white;
      box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.12);
    }

    .inactive {
    }

    .completed {
      opacity: 0.3;
    }
  }
}
