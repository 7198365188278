.not-interested-message-modal {
  &__subtitle {
    font-weight: 400;
  }
  &__content {
    padding: 40px;
    textarea {
      padding: 15px;
    }
  }
}
