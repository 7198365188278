.inviteOnboarding {
  &_wrapper {
    min-height: 100vh;
    padding-bottom: 100px;
    background-color: $off-white;

    .sms-extra-info {
      color: $mid-gray;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 700;
    }
    .login-instead {
      display: flex;
      align-items: center;
      width: 400px;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 25px;
      padding: 10px;
      border: 1px dotted $mid-gray;
      border-radius: 3px;
      font-size: 12px;

      button {
        height: auto;
        padding: 5px;
        margin: 0 5px 0 10px;
        font-size: 12px;
      }
    }
  }

  &_intro {
    width: 400px;
    margin: 0 auto;

    &.smaller {
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &_title {
    margin: 0 auto 80px;
    width: 400px;
  }

  &_steps {
    &__wrapper {
      width: 400px;
      margin: 0 auto;
    }
  }

  &_points__wrapper.ant-steps {
    margin-bottom: 30px;
    .ant-steps-item {
      &-icon {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        .ant-steps-icon {
          top: 0;
        }
      }
      &-wait {
        .ant-steps-item-icon {
          background-color: $light-gray;
          color: $mid-gray;
          border-color: $light-gray;
        }
      }
      &-process,
      &-active {
        .ant-steps-item-icon {
          background-color: $link;
          color: #fff;
          border-color: $link;
        }
      }
      &-content {
        .ant-steps-item-title {
          &:after {
            top: -15px;
            left: calc(100% + 30px);
          }
        }
      }
      &-finish {
        .ant-steps-item-icon {
          border-color: $link;
          svg {
            color: $link;
          }
        }
        .ant-steps-item-title {
          &:after {
            background-color: $link !important;
          }
        }
      }
    }
  }

  &_form__wrapper {
    .settings-form {
      .field {
        .input {
          width: 100%;
        }
        .react-number-input-search {
          width: 100%;
          height: 60px;
          background: $white;
          border: none;
          font-size: 14px;
          line-height: 17px;
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          outline: none;
          padding-left: 60px;
        }
      }
    }
    .custom-control.custom-switch {
      .ant-switch-checked {
        background-color: $link;
      }
    }
    .subscription-select {
      margin: 0px;
      .editBlock_field__label {
        margin: 20px 0px 10px 20px;
        padding: 0px;
      }
      .editBlock_select__field {
        width: 100%;
      }
    }
  }

  &_form {
    &__one {
      .inviteOnboarding_btn__wrapper {
        justify-content: flex-end;
      }
    }
  }

  &_btn {
    padding: 20px 65px;
    &:first-of-type {
      margin-right: 30px;
    }
    &.ant-btn {
      min-width: 100px;
      font-size: 1.25rem;
      min-height: 50px;
      background-color: $link;
      border-color: $link;
      color: $white;
      &:hover {
        background-color: rgba($link, 0.8);
        color: #fff;
        border-color: $link;
      }
    }
    &__wrapper {
      width: 400px;
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
    }
  }
}
