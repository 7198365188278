.main-sidebar {
  &_wrapper {
    max-width: 230px;
  }

  &_list {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    position: relative;
  }

  &_section {
    &-locked {
      margin-top: 10px;
      margin-left: 10px;
      width: fit-content;
      max-width: 180px;
      align-items: start;
    }
    &:not(:first-of-type) {
      padding-top: 35px;
    }
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 10px;
    font-weight: 700;

    &.blue {
      color: $link;
    }

    &.green {
      color: $green;
    }

    &.black {
      color: $discrete-text;
    }

    &.disabled {
      opacity: 0.1;
    }
  }

  &_sidebar-star-no-04-05-subs {
    position: absolute;
    height: auto;
    top: 4px;
    right: -22px;
  }

  &_btn {
    color: $black;
    font-size: 16px;
    border: none;
    padding: 10px 0px 10px 10px;
    background: transparent;
    display: flex;
    align-items: center;
    border-radius: 3px;
    white-space: nowrap;

    &.__wrap {
      white-space: normal;
    }

    &.active {
      background: #efefef !important;
      font-weight: bold;

      &:hover {
        color: $black;
      }
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.1);
    }

    &[disabled] > svg {
      opacity: 0.1;
    }

    img,
    svg {
      margin: 0 10px 0 0;
      width: 20px;
      height: 20px;
      min-width: 20px;
    }

    .live {
      color: $red;
      line-height: 1;
      display: flex;
      text-transform: uppercase;
      font-size: 9px;
      margin-top: 4px;
      margin-left: 5px;
      font-weight: bold;
    }
  }

  &__beaver {
    width: 190px;
    margin-top: 40px;
  }
}

.abs-bg_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
  z-index: 100;

  &.showSidebar {
    height: 100%;
    opacity: 1;
    transition: none;
    animation: fadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    pointer-events: auto;
  }
}
