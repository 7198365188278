@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .main-sidebar {
    &_list {
      margin-top: 20px;
    }
  }
  .modal-ent {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .header {
    .btn-custom_showSidebar {
      margin-right: 15px;
    }
    &__logo-wrapper {
      margin-right: 0;
    }
  }

  .main-sidebar {
    &_wrapper {
      padding-top: 20px !important;
    }
  }
}

@media (min-width: 320px) and (max-width: 767.98px) {
  .header {
    &_profile {
      &__select {
        display: none;
      }
      &__name {
        display: none;
      }
    }

    .language {
      .language_select {
        .ant-select-selector {
          padding: 0px 10px 0px 45px !important;
          .ant-select-selection-search {
            left: 0px;
            right: 0px;
          }
        }
      }
      & > svg {
        left: 10px;
      }
    }
  }

  .main-sidebar {
    &_wrapper {
      overflow: scroll;
    }
    &_list {
      margin-top: 10px;
    }
  }

  .footer {
    &__info {
      flex-direction: column;
    }
  }

  .modal-ent {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 320px) and (max-width: 991.98px) {
  .header {
    padding: 8px 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
    z-index: 99;
    &_btn__showSidebar {
      display: flex;
      opacity: 1;
    }
  }

  .main-sidebar {
    &_wrapper {
      position: fixed;
      min-width: 280px;
      width: auto;
      height: 100%;
      padding: 30px 25px;
      top: 0;
      background-color: $white;
      z-index: 101;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
      transition: left 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
      &.disabled {
        left: -100%;
      }
      &.showSidebar {
        left: 0;
      }
      .main-sidebar__beaver {
        margin: 10px 0px 0px 0px !important;
        .saying-beaver__title {
          font-size: 14px;
        }
      }
    }
    &_section {
      padding: 20px 0px 0px 18px;
    }
    &_btn {
      &__close {
        display: block;
      }
    }
  }

  .footer {
    &__info {
      padding: 20px 20px 0px 20px;
      width: 100%;
    }
  }
}

/* Johans quick fix for the modal advertisement in mobile */

.modal-ent.__transparent-title > .modal-ent__wrapper .modal-ent__content {
  background: transparent;
}

@media (min-width: 0) and (max-width: 767.98px) {
  .modal-ent.__transparent-title > .modal-ent__wrapper .modal-ent__header {
    color: #ffffff;
  }

  .advertisement-video-modal {
    width: 100vw;
    max-width: 1280px;
  }

  .advertisement-video-modal > .modal-ent__content {
    height: auto;
    width: 100vw;
    max-width: 1280px;
  }
}
